<template>
	<div :class="currentStep">

		<div v-if="milestoneBoardData.target === 'stage'">
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="24">
          <h6 class="font-semibold m-0">LIVE ENV(개발환경) 전환</h6>
        </a-col>
      </a-row>
      <br>
      <ul style="margin: 0; padding: 0 0 0 10px">
        <li>라이브 전환되면 스테이지상태로 다시 돌아 올수 없습니다.</li>
        <li>개발된 내용을 정확히 확인 하시고 라이브로 전환해 주세요.</li>
        <li>라이브환경에 디자인, 앱빌드, 스테틱시트, 서버코드를 적용하는 작업을 진행 합니다.</li>
      </ul>
			<br>
			<a-button type="primary" :disabled="isLoading" size="small" @click="onSubmit">LIVE로 변경 하기</a-button>
		</div>
    <div v-else>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="24">
          <h6 class="font-semibold m-0">LIVE ENV(개발환경)</h6>
        </a-col>
      </a-row>
      <br>
      <ul style="margin: 0; padding: 0 0 0 10px">
        <li>마켓 업로드에 실페한 경우 실패한 마켓의 빌드만 다시 빌드하여 다시 확인 하고 QA를 진행 합니다.</li>
      </ul>
    </div>
	</div>
</template>

<script>

  import { mapGetters } from 'vuex';

  export default ({
    props: [],
    computed: {
      ...mapGetters([
        'page',
        'config',
        'loginUser',
        'milestoneBoardData',
        'apps',
      ]),
    },
    mounted() {
      this.setCurrentAction();
    },
    watch: {
      milestoneBoardData() {
        this.setCurrentAction();
      }
    },
    methods: {
      setCurrentAction() {
        this.isLoading = false;
        this.isLive = (this.milestoneBoardData.target === 'live') ? true : false;
			},
      onSubmit() {
        const formData = new FormData();

        this.isLoading = true;
        formData.append('app', this.page.appId);
        formData.append('key', this.page.milestoneId);
        formData.append('target', 'live');
        this.$store.dispatch('SUBMIT_TARGET_CHANGE', formData);
			},
    },
    data() {
      return {
        currentStep: 'target_change',
        isLive: '',
        isLoading: false,
      }
    },
  })

</script>

<style lang="scss" scoped>
	.confirm_game {
		margin-bottom: 2rem;
	}
</style>

export default {
  base: state => state.base,
  page: state => state.page,
  isLoading: state => state.isLoading,
  loginUser: state => state.loginUser,

  apps: state => state.apps,
  config: state => state.config,
  milestoneListData: state => state.milestoneListData,
  milestoneOverviewData: state => state.milestoneOverviewData,
  milestoneBoardData: state => state.milestoneBoardData,
  notice: state => state.notice,

  // message
  messageToast: state => state.message.toast,
  messageAlert: state => state.message.alert,
  messageConfirm: state => state.message.confirm,
  messageNotification: state => state.message.notification,
};

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////
import Vue from 'vue'
import VueRouter from 'vue-router'

// import DefaultLayout from '@/layouts/Default.vue'
import DashboardLayout from '@/layouts/Dashboard.vue'

import NotFound from '@/views/404';
import Overview from '@/views/Overview';
import Milestone from '@/views/Milestone';
import Board from '@/views/Board';

// Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.use(VueRouter);

let routes = [
  {
    path: '*',
    component: NotFound,
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   redirect: '/dashboard',
  // },
  {
    path: '/',
    name: 'Overview',
    layout: "dashboard",
    component: Overview,
  },
  {
    path: '/:app',
    name: 'Milestone',
    layout: "dashboard",
    component: Milestone,
  },
  {
    path: '/:app/:milestone',
    name: 'Board',
    layout: "dashboard",
    component: Board,
  },
];


const addLayoutToRoute = ( route, parentLayout = "default" ) => {
  route.meta = route.meta || {} ;
  route.meta.layout = route.layout || parentLayout ;

  if( route.children ) {
    route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
  }
  return route ;
};

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to) { // , from, savedPosition
    if ( to.hash ) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
    return {
      x: 0,
      y: 0,
      behavior: 'smooth',
    }
  }
});

export default router


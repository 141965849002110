<template>

	<!-- Main Sidebar -->
	<a-layout-sider collapsible
									class="sider-primary"
									breakpoint="lg"
									collapsed-width="0"
									width="250px"
									:collapsed="sidebarCollapsed"
									@collapse="$emit('toggleSidebar', ! sidebarCollapsed)"
									:trigger="null"
									:class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
									theme="light"
									:style="{ backgroundColor: 'transparent',}">
		<div class="brand">
			<img src="/static/frontend/images/icon.png" alt="SundayToz" class="icon">
			<span>MileStoneOps</span>
		</div>
		<hr>

		<a-menu theme="light" mode="inline">
			<a-menu-item v-for="(app, appId) in apps" :key="appId">
				<router-link :to="`/${app.name}`">
					<a-badge :dot="notice[app.name].hasUpdate"><span class="label">{{app.full_name}}</span></a-badge>
					<!--<span class="label">{{app.full_name}}</span>-->
				</router-link>
			</a-menu-item>
		</a-menu>

		<div class="aside-footer">
			<div class="footer-box">
				<p>
					문의 사항은 BI팀 <br>신재훈님에게 <br>문의 해 주세요.
				</p>
        <br><br>
			</div>
		</div>


		<template>
			<a-modal v-model="visibleHelpModal" :title="`HELP`" width="100%" height="100%">
				<template slot="footer">
					<a-button key="back" @click="hiddenHelpModal">
						닫기
					</a-button>
				</template>
				<video width="100%" controls autoplay playsinline="" style="padding: 0 2%">
					<source type="video/mp4"
									src="https://s3.ap-northeast-1.amazonaws.com/stz.liveops/liveOps_guide_fin.mp4">
				</video>
			</a-modal>
		</template>

	</a-layout-sider>
	<!-- / Main Sidebar -->

</template>

<script>
  import { mapGetters } from 'vuex';

  // import timerCount from '@/mixins/timerCount';

	export default ({
		props: {
			// Sidebar collapsed status.
			sidebarCollapsed: {
				type: Boolean,
				default: false,
			},

			// Main sidebar color.
			sidebarColor: {
				type: String,
				default: "primary",
			},

			// Main sidebar theme : light, white, dark.
			sidebarTheme: {
				type: String,
				default: "light",
			},
		},
    mixins: [
      // timerCount,
    ],
    computed: {
      ...mapGetters([
        'base',
				'apps',
				'notice',
      ]),
    },
    methods: {
      showHelpModal() {
        this.visibleHelpModal = true;
      },
      hiddenHelpModal() {
        this.visibleHelpModal = false;
      },
    },
    data() {
      return {
        visibleHelpModal: false,
      }
    },

  })

</script>

const validDocsURL = (str) => {
  let returnValue = false;
  const urls = [
    'https://www.notion.so/',
    'https://docs.google.com/',
    'https://drive.google.com/',
  ];

  urls.forEach((url) => {
    if (str.includes(url)) {
      returnValue = true;
    }
  });
  return returnValue;
};

const validGithubURL = (str) => {
  const url = 'https://github.com/';

  return str.includes(url);
};

export default {
  validDocsURL,
  validGithubURL,
};

export const INITIALIZE_USER = 'INITIALIZE_USER';
export const SET_TARGET_PAGE = 'SET_TARGET_PAGE';
export const SET_LOGIN_USER_DATA = 'SET_LOGIN_USER_DATA';
export const SET_APPS_DATA = 'SET_APPS_DATA';
export const SET_APP_LIVE_OPS_DATA = 'SET_APP_LIVE_OPS_DATA';
export const SET_CONFIG_DATA = 'SET_CONFIG_DATA';
export const RESET_NOTICE_DATA = 'RESET_NOTICE_DATA';
export const SET_NOTICE_DATA = 'SET_NOTICE_DATA';
export const SET_MILESTONE_LIST_DATA = 'SET_MILESTONE_LIST_DATA';
export const SET_MILESTONE_OVERVIEW_DATA = 'SET_MILESTONE_OVERVIEW_DATA';
export const SET_MILESTONE_OVERVIEW_DETAIL_DATA = 'SET_MILESTONE_OVERVIEW_DETAIL_DATA';
export const SET_MILESTONE_LOG_DATA = 'SET_MILESTONE_LOG_DATA';
export const SET_MILESTONE_BOARD_DATA = 'SET_MILESTONE_BOARD_DATA';

// loading
export const SET_LOADING = 'SET_LOADING';
// 사용자 알림
export const SET_MESSAGE = 'SET_MESSAGE';

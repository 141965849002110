import Vue from 'vue';
import Vuex from 'vuex';
import CreatePersistedState from 'vuex-persistedstate';
import state from './state';
import mutations from './mutation';
import getters from './getter';
import actions from './actions';

Vue.use(Vuex);

const vuexSessionPersisted = new CreatePersistedState({
  key: 'LiveOps-session',
  storage: window.sessionStorage,
  reducer: states => ({
    member: states.member,
    ordering: states.ordering,
  }),
});

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  plugins: [vuexSessionPersisted],
});

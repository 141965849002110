<template>
  <div>
<!--    <BoardChart :milestoneBoardData="this.milestoneBoardData"/>-->
    <br><br>
    <TempBoard/>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex';

  // import BoardChart from "@/components/Milestone/BoardChart";
  import TempBoard from "@/components/Milestone/TempBoard"

  export default ({
    components: {
      // BoardChart,
      TempBoard,
    },
    computed: {
      ...mapGetters([
        'page',
        'milestoneBoardData',
      ]),
    },
  })

</script>

<style lang="scss">
</style>

import Vue from 'vue'
import store from '@/store'
import { sync } from 'vuex-router-sync'
import App from './App.vue'
import router from './router'
import GlobalComponents from './globalComponents'
import './assets/scss/app.scss'

Vue.use(GlobalComponents);
Vue.config.productionTip = false;

sync(store, router);

new Vue({
  el: '#app',
  store,
  render: h => h(App),
  router,
});
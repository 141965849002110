import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

const GlobalComponents = {
  install(Vue) {
    Vue.use(Antd);
  },
};

export default GlobalComponents;

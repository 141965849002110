export default {
  methods: {
    openNotification(description) {
      this.$notification.open({
        message: '확인해 주세요.',
        description,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    },

    getLayoutValue(stepId) {
      const layoutValue = {
        boardWidth: 24,
        actionVisibility: false,
      };

      this.config.step[stepId].authority.forEach((authority) => {
        if (this.loginUser.permission[this.page.appId].includes(authority) && this.milestoneBoardData.status === 'create') {
          layoutValue.actionVisibility = true;
          layoutValue.boardWidth= 16;
        }
      });

      return layoutValue;
    },

    checkDownloadable(stepId, item) {
      let hasAuthority = false;

      this.config.step[stepId].authority.forEach((authority) => {
        if (this.loginUser.permission[this.page.appId].includes(authority) && this.milestoneBoardData.status !== 'close') {
          hasAuthority = true;
        } else if (item.approve && (this.milestoneBoardData.version === item.milestone_version)) {
          hasAuthority = true;
        }
      });
      return hasAuthority;
    },
  },
};

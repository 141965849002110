<!-- ConfirmStage의 Design 부문 fork입니다. 표기정도만 다릅니다.-->

<template>
	<div :class="stepId">
		<a-row type="flex" align="middle">
			<a-col :span="24" :md="24">
				<h6 class="font-semibold m-0">디자인 확인</h6>
			</a-col>
		</a-row>
		<br>
		<div v-if="milestoneBoardData.available_steps.includes(stepId)">
			<ol style="margin: 0; padding-left: 1rem">
				<li>{{milestoneBoardData.version}}의 기획서와 TC를 다운 받아 내용을 확인합니다.</li>
				<li v-if="milestoneBoardData && {}.hasOwnProperty.call(milestoneBoardData, 'tasks')">
					디자인 내용을 확인해 주세요.
				</li>
			</ol>
			<br>
			<div>
				<template v-if="milestoneBoardData.completed_steps.includes(stepId)">
					<a-button type="primary" disabled size="small">이미 결재 하였습니다.</a-button>
				</template>
        <template v-else-if="notClosedTasks.length > 0">
          <strong style="color:tomato;">아직 완료되지 않은 작업(들)이 있습니다.</strong>
				</template>
				<template v-else-if="milestoneBoardData.available_steps.includes(stepId)">
					<a-input v-model="description" placeholder="간략히 내용을 정리해 주세요" />
					<br>
					<br>
					<a-button type="primary" :disabled="isLoading" size="small" @click="onSubmit">디자인 확인 하였습니다.</a-button>
				</template>
        <template v-else>
          ⛔️ 내용 없음
				</template>
			</div>
		</div>
		<div v-else>
			<!--{{config.authority}}-->
			<template v-if="Object.keys(config.step).length > 0">
				이하의 진행 중인 스텝
        <hr/>
        <div v-for="(remainingStep) in getRemainingSteps()" :key="remainingStep">
          <strong>
            - {{config.step[remainingStep].label}}
          </strong>
        </div>
        <hr/>
				이 모두 완료 되면 해당 내용을 확인 할 수 있습니다.<br>
			</template>

		</div>
	</div>
</template>

<script>

  import { mapGetters } from 'vuex';

  export default ({
    props: [],
    computed: {
      ...mapGetters([
        'page',
        'config',
        'loginUser',
        'milestoneBoardData',
        'apps',
      ]),
      notClosedTasks() {
        return this.milestoneBoardData.tasks.filter((task) => {
          const childrenKeys = task.children.map(child => child.key).sort();
          const closedTasks = task.closed.sort();
          return childrenKeys.length !== closedTasks.length
            || !closedTasks.every((val, index) => val === childrenKeys[index]);
        });
      }
    },
    mounted() {
      this.setCurrentAction();
    },
    watch: {
      milestoneBoardData() {
        this.setCurrentAction();
      }
    },
    methods: {
      getRemainingSteps() {
        const remainingStep = [];

        this.milestoneBoardData.available_steps.forEach((step) => {
          if (!this.milestoneBoardData.completed_steps.includes(step)) {
            remainingStep.push(step);
          }
				});
        return remainingStep;
			},
      setCurrentAction() {
        this.isLoading = false;
			},
      openNotification(description) {
        this.$notification.open({
          message: '확인해 주세요.',
          description,
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      },
      onSubmit() {
        const formData = new FormData();

        this.isLoading = true;
        if (this.description === '') {
          this.openNotification('빠진 부분이 있는지 다시 한번 확인해 주세요.');
          this.isLoading = false;
        } else {
          formData.append('app', this.page.appId);
          formData.append('key', this.page.milestoneId);
          formData.append('milestone_version', this.milestoneBoardData.version);
          formData.append('description', this.description);
          formData.append('env', this.milestoneBoardData.target);
          formData.append('tasks', JSON.stringify(this.milestoneBoardData.tasks));
          formData.append('step', this.stepId);

          this.$store.dispatch('SUBMIT_CONFIRM_STAGE', formData);
        }
			},

    },
    data() {
      return {
        stepId: 'confirm_game',
        description: '',
        isLoading: false,
      }
    },
  })

</script>

<style lang="scss" scoped>
	.confirm_game {
		margin-bottom: 2rem;
	}
</style>

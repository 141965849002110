<template>
  <div :class="stepId" v-if="{}.hasOwnProperty.call(milestoneBoardData.step, stepId)">

    <BoardHeader :header="header" :stepId="stepId"/>

    <a-row type="flex" :gutter="24">
      <a-col :span="24" :md="getLayoutValue(stepId).boardWidth" class="mb-24">
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }">
          <a-list item-layout="vertical" :data-source="getHistoryData">

            <a-list-item slot="renderItem" slot-scope="item">
              <a-list-item-meta :description="item.description">
                <div slot="title">
                  <strong :style="item.approve ? 'color: red' : 'color: black'">#{{ item.version }}</strong>
                  <a v-if="checkDownloadable(stepId, item)" :href="item.link" target="_blank">
                    <a-button type="link" icon="link" style="float:right">
                      기획서 {{ {}.hasOwnProperty.call(item, 'server') ? `( ${item.server} )` : '' }}
                    </a-button>
                  </a>
                  <span class="board-dt">{{item.dt}}</span>
                </div>
                <a-avatar slot="avatar">{{item.milestone_version}}</a-avatar>
              </a-list-item-meta>
              <a-card v-if="item.tasks" :bordered="false" class="card-billing-info">
                <div class="col-info">
                  <a-tree>
                    <template v-for="(task) in item.tasks" >
                      <template v-if="task.children.length > 0">
                        <a-tree-node :key="task.key">
                          <template #title>
                            <strong>- {{task.title}}</strong>
                          </template>
                          <a-tree-node v-for="(child) in task.children" :key="child.key" >
                            <template #title>
                              <strong :style="task.closed.includes(child.key) ? 'color: blue' : 'opacity: 0.5'">{{child.title}}</strong>
                            </template>
                          </a-tree-node>
                        </a-tree-node>
                      </template>
                    </template>
                  </a-tree>
                </div>
              </a-card>
            </a-list-item>
            <div v-if="milestoneBoardData.step[stepId].history.length > 1" slot="footer">
              <a-button slot="actions" type="" size="small" @click="toggleShowConfirmOnly(stepId)">
                {{showConfirmOnly ? '최종 승인된 기획만 보기' : '모든 기획 보기'}}
              </a-button>
            </div>
          </a-list>
        </a-card>
      </a-col>
      <a-col v-if="getLayoutValue(stepId).actionVisibility" :span="24" :md="8" class="mb-24">
        <!-- 기획 ----------------------------------------------->
        <template v-if="milestoneBoardData.status !== 'close'">
          <UploadPlan v-if="loginUser.permission[page.appId].includes('upload_plan')"/>
        </template>
        <!-- 기획 승인 ----------------------------------------------->

        <ConfirmPlan v-if="loginUser.permission[page.appId].includes('confirm_plan')"/>
      </a-col>
    </a-row>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex';

  import UploadPlan from "@/components/ActionForm/UploadPlan";
  import ConfirmPlan from "@/components/ActionForm/ConfirmPlan";
  import BoardHeader from "@/components/Step/Parts/BoardHeader";

  import getStepMembers from '@/mixins/getStepMembers';

  export default ({
    mixins: [
      getStepMembers,
    ],
    components: {
      BoardHeader,
      UploadPlan,
      ConfirmPlan,
    },
    computed: {
      ...mapGetters([
        'page',
        'loginUser',
        'milestoneBoardData',
        'apps',
        'config',
      ]),

      getHistoryData() {
        const history = this.milestoneBoardData.step[this.stepId].history;
        const permission = this.loginUser.permission[this.page.appId];
        const auth = ['upload_plan', 'confirm_plan'];
        let returnList = history;

        if (permission && history.length > 0) {
          if (permission.includes(auth[0]) || permission.includes(auth[1])) {
            if (!this.showConfirmOnly) {
              returnList = [history[0]];
            }
          } else {
            if (!this.showConfirmOnly) {
              const findDone = history.find((item) => item.milestone_version === this.milestoneBoardData.version);
              returnList = findDone ? [findDone] : [];
            }
          }
        }
        return returnList;
      },
    },
    methods: {
      toggleShowConfirmOnly() {
        this.showConfirmOnly = !this.showConfirmOnly;
      },
    },
    data() {
      return {
        stepId: 'plan',
        header: '기획',
        showConfirmOnly: false,
      };
    },
  })

</script>

<style lang="scss" scoped>
  .board-dt {
    font-weight: 100;
    padding-left: .5rem;
    color: #999;
    font-size: .7rem
  }
</style>

// import services from '@/store/mockup/define/services';
// import teams from '@/store/mockup/define/teams';
// import jobs from '@/store/mockup/define/jobs';
// import members from '@/store/mockup/define/members';

import {
  SET_TARGET_PAGE,
  INITIALIZE_USER,
  SET_LOGIN_USER_DATA,
  SET_APPS_DATA,
  SET_APP_LIVE_OPS_DATA,
  SET_CONFIG_DATA,
  RESET_NOTICE_DATA,
  SET_NOTICE_DATA,
  SET_MILESTONE_LIST_DATA,
  SET_MILESTONE_OVERVIEW_DATA,
  SET_MILESTONE_OVERVIEW_DETAIL_DATA,
  SET_MILESTONE_LOG_DATA,
  SET_MILESTONE_BOARD_DATA,

  // loading
  SET_LOADING,
  // 사용자 알림
  SET_MESSAGE,
} from './mutation-types';

export default {

  [INITIALIZE_USER](state, res) {
    // console.log('INITIALIZE_USER (mutation) =========================== ', res);
    const payload = res.data.data;

    if (payload.user && payload.user.login) {
      this.commit('SET_LOGIN_USER_DATA', payload.user);
      this.commit('SET_TARGET_PAGE');

      const permission = state.loginUser.permission;

      if (Object.keys(permission).length > 0) {
        this.commit('SET_APPS_DATA', payload.apps);
        this.commit('RESET_NOTICE_DATA');
        this.commit('SET_CONFIG_DATA', payload.config);
        if ({}.hasOwnProperty.call(permission, state.page.appId)) {
          // this.commit('SET_APPS_DATA', payload.apps);
          // this.commit('RESET_NOTICE_DATA');
          // this.commit('SET_CONFIG_DATA', payload.config);
          this.dispatch('ACTION_PAGE_TRIGGER');
          this.dispatch('GET_NOTICE_DATA');
        } else {
          if (state.base.env === 'development') {
            window.location.href = `http://localhost:8080/${Object.keys(permission)[0]}`;
          } else {
            window.location.href = `${state.base.baseUrl}${Object.keys(permission)[0]}`;
          }
        }
      } else {
        alert('MilestoneOps 접근 권한이 없습니다. 관련 권한 획득 후 진행하십시오.');
      }
    } else {
      window.location.href = `${state.base.baseUrl}/admin/login`;
    }
  },

  [SET_TARGET_PAGE](state) {
    // console.log('SET_TARGET_PAGE (mutation) =========================== ');
    const routePath = state.route.path;
    const routePathSplit = routePath.split('/');
    const appId = routePathSplit[1];
    const milestoneId = routePathSplit[2];

    state.page = { appId, milestoneId };
  },

  [SET_LOGIN_USER_DATA](state, payload) {
    // console.log('SET_LOGIN_USER_DATA(mutation) =========================== ', payload);
    state.loginUser = payload;
  },

  [SET_APPS_DATA](state, payload) {
    // console.log('SET_APPS_DATA(mutation) =========================== ', payload);
    Object.keys(payload).forEach((appId) => {
      payload[appId].available = Object.keys(payload[appId].liveOps).length > 0
    });
    state.apps = payload;
  },

  [SET_APP_LIVE_OPS_DATA](state, payload) {
    // console.log('SET_APP_LIVE_OPS_DATA(mutation) =========================== ', payload);
    const tempApps = { ...state.apps };

    tempApps[payload.name].liveOps = payload.liveOps;
    this.commit('SET_APPS_DATA', tempApps);
  },

  [SET_CONFIG_DATA](state, payload) {
    // console.log('SET_CONFIG_DATA(mutation) =========================== ', payload);
    state.config = payload
  },

  [RESET_NOTICE_DATA](state) {
    // console.log('RESET_NOTICE_DATA(mutation) =========================== ');
    if (Object.keys(state.notice).length === 0) {
      const resetNotice = {};

      Object.keys(state.apps).forEach((appId) => {
        resetNotice[appId] = {
          lastIndex: null,
          hasUpdate: false,
          update: 0,
          milestone: {},
        };
      });
      state.notice = resetNotice;
    }
  },

  [SET_NOTICE_DATA](state, payload) {
    // console.log('SET_NOTICE_DATA(mutation) =========================== ', payload);
    const tempNotice = JSON.parse(JSON.stringify(state.notice));

    Object.keys(tempNotice).forEach((appId) => {
      Object.keys(payload[appId].milestone).forEach((milestoneId) => {
        const payloadMilestoneLastIndex = payload[appId].milestone[milestoneId];
        const tempMilestone = tempNotice[appId].milestone;

        if (tempMilestone[milestoneId]) {
          const isUpdate = payloadMilestoneLastIndex > tempMilestone[milestoneId].lastIndex;

          if (state.page.appId === appId && state.page.milestoneId === milestoneId) {
            if (isUpdate) {
              tempMilestone[milestoneId].lastIndex = payloadMilestoneLastIndex;
              tempMilestone[milestoneId].hasUpdate = false;
              if (payloadMilestoneLastIndex !== state.milestoneBoardData.lastIndex) {
                this.dispatch('GET_MILESTONE_BOARD_DATA');
              }
            }
          } else {
            tempMilestone[milestoneId].hasUpdate = isUpdate;
          }
        } else {
          const isUpdate = payloadMilestoneLastIndex > state.notice[state.page.appId].lastIndex;

          tempMilestone[milestoneId] = {
            lastIndex: state.notice[state.page.appId].lastIndex,
            hasUpdate: isUpdate,
          };
        }
        tempNotice[appId].milestone = tempMilestone;
      });


      if (Number.isInteger(state.notice[appId].lastIndex)) {
        const isChange = payload[appId].lastIndex > state.notice[appId].lastIndex;

        if (isChange) {
          if (state.page.appId === appId && state.page.milestoneId) {
            tempNotice[appId].hasUpdate = isChange;
          } else if (state.page.appId === appId)  {
            let allClearMilestone = true;

            Object.keys(tempNotice[appId].milestone).forEach((id) => {
              if (tempNotice[appId].milestone[id].hasUpdate) {
                allClearMilestone = false;
              }
            });

            if (allClearMilestone) {
              tempNotice[appId].lastIndex = payload[appId].lastIndex;
            } else {
              tempNotice[appId].hasUpdate = isChange;
            }
          } else {
            tempNotice[appId].hasUpdate = isChange;
          }
        } else {
          tempNotice[appId].hasUpdate = isChange;
        }
      } else {
        tempNotice[appId].lastIndex = payload[appId].lastIndex;
      }
    });
    state.notice = tempNotice;
    setTimeout(() => {
      this.dispatch('GET_NOTICE_DATA');
    }, 1000);
  },

  [SET_MILESTONE_LIST_DATA](state, payload) {
    // console.log('SET_MILESTONE_LIST_DATA(mutation) =========================== ', payload);
    state.milestoneListData = payload;
  },

  [SET_MILESTONE_OVERVIEW_DATA](state, payload) {
    // console.log('SET_MILESTONE_OVERVIEW_DATA(mutation) =========================== ', payload);
    const reg = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g
    const total = {}

    payload.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        if (!{}.hasOwnProperty.call(total, key)) {
          if (key === 'vol1' ||key === 'vol2' ||key === 'vol3' ||key === 'vol4' ||key === 'vol5') {
            total[key] = {
              cnt: 0,
              day: 0
            }
          } else {
            total[key] = 0
          }
        }
        if (key === 'vol1' ||key === 'vol2' ||key === 'vol3' ||key === 'vol4' ||key === 'vol5') {

          if (obj[key]) {
            total[key].cnt = total[key].cnt + obj[key].cnt
            total[key].day = total[key].day + obj[key].day
          }
        } else {
          total[key] = total[key] + obj[key]
        }

      })
    })
    total.name = 'name'
    total.title = ''
    total.type = payload[0].type
    payload.push(total)

    payload.forEach((obj) => {
      obj.detail = []
      obj.name = obj.title + obj.type;
      // obj.title = obj.title + obj.type;
      obj.iaa = Math.round(obj.iaa).toString().replace(reg, ",") + '원';
      obj.iap = Math.round(obj.iap).toString().replace(reg, ",") + '원';
      obj.vol1 = obj.vol1 ? obj.vol1 : '';
      obj.vol2 = obj.vol2 ? obj.vol2 : '';
      obj.vol3 = obj.vol3 ? obj.vol3 : '';
      obj.vol4 = obj.vol4 ? obj.vol4 : '';
      obj.vol5 = obj.vol5 ? obj.vol5 : '';
    })

    state.milestoneOverviewData = payload;
  },

  [SET_MILESTONE_OVERVIEW_DETAIL_DATA](state, payload) {
    // console.log('SET_MILESTONE_OVERVIEW_DETAIL_DATA(mutation) =========================== ', payload);
    const milestoneOverviewData = [...state.milestoneOverviewData];

    milestoneOverviewData.forEach((item) => {
      if (item.title === payload.title) {
        item.detail = payload.detail
      }
    })
    state.milestoneOverviewData = milestoneOverviewData;
  },

  [SET_MILESTONE_LOG_DATA](state, payload) {
    // console.log('SET_MILESTONE_LOG_DATA(mutation) =========================== ', payload);
    state.milestoneListData.forEach((milestone) => {
      if (milestone.key === payload.key) {
        milestone.timeLine = payload.timeLine;
      }
    });
  },

  [SET_MILESTONE_BOARD_DATA](state, payload) {
    // console.log('SET_MILESTONE_BOARD_DATA(mutation) =========================== ', payload);
    if (payload && {}.hasOwnProperty.call(payload, 'tasks')) {
      // Step1 : 각 task child마다 완료가 필요한 step들 가져옴
      const reqSteps = {};
      
      payload.tasks.forEach(task => {
        task.children.forEach(child => reqSteps[child.key] = child.stepRoles);
      })

      const developmentSteps = Object.keys(state.config.step)
        .filter(stepId => state.config.step[stepId].category === 'development');
      
      developmentSteps.forEach((stepId) => {
        if ({}.hasOwnProperty.call(payload.step, stepId)) {
          const approvedItem = payload.step[stepId].history.find(item => item.approve);

          if (approvedItem && {}.hasOwnProperty.call(approvedItem, 'selectedTasks')) {
            approvedItem.selectedTasks.forEach(selectedTask => {
              if (!{}.hasOwnProperty.call(reqSteps, selectedTask)) {
                return;
              }

              if (reqSteps[selectedTask].length === 0) {
                return;
              }

              reqSteps[selectedTask] = reqSteps[selectedTask].filter(reqStep => reqStep !== stepId);
            })
          }
          if (approvedItem && {}.hasOwnProperty.call(approvedItem, 'selectedCheckpoints')) {
            approvedItem.selectedCheckpoints.forEach((key) => {
              if (!payload.checkpoints.includes(key)) {
                payload.checkpoints.push(key);
              }
            });
          }
        }
      });
      payload.tasks.forEach((task) => {
        task.children.forEach((child) => {
          if (({}.hasOwnProperty.call(reqSteps, child.key))
            && (reqSteps[child.key].length === 0)) {
              task.closed.push(child.key);
          }
          
        });

        task.closed = [...new Set(task.closed)];
      });
    }
    state.milestoneBoardData = payload;
  },

  // loading ///////////////////////////////////////////////////////////////////////////////////////
  [SET_LOADING](state, payload) {
    // console.log('SET_LOADING_START (mutation) =========================== ', payload);
    state.isLoading = payload;
  },

  // 사용자 알림 ///////////////////////////////////////////////////////////////////////////////////////
  [SET_MESSAGE](state, payload) {
    // console.log('SET_MESSAGE(mutation) =========================== ', payload);
    state.message[payload.method] = payload;
  },
};

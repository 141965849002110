<template>
  <div>

    <a-card :bordered="false" class="header-solid h-full" :bodyStyle="bodyStyle">
      <template #title>
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12">
            <h5 class="font-semibold m-0">
              {{{}.hasOwnProperty.call(apps, page.appId) ? apps[page.appId].full_name : page.appId}}
              <a-button v-if="{}.hasOwnProperty.call(loginUser, 'permission')" type="link" size="small" shape="circle" icon="setting" @click="showAppSetup" />
            </h5>
          </a-col>
          <a-col v-if="{}.hasOwnProperty.call(loginUser, 'permission') && loginUser.permission[page.appId].includes('create_milestone') && apps[page.appId].available" :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end" >
            <a-button type="primary" @click="showNewMilestoneModal">New Milestone</a-button>
          </a-col>
        </a-row>
      </template>

      <PopupSetup :visibleAppSetupModal="visibleAppSetupModal" @hideAppSetup="hideAppSetup"></PopupSetup>
      <PopupNew :visibleNewMilestoneModal="visibleNewMilestoneModal" @hideNewMilestoneModal="hideNewMilestoneModal"></PopupNew>


    </a-card>
    <br><br>

    <MilestoneTable/>
    <br><br>
    <ProcessChart/>
    <br><br>
    <MilestoneOverview v-if="loginUser.permission && loginUser.permission[page.appId].includes('view_kpi')"/>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex';

  import PopupSetup from "@/components/Milestone/PopupSetup";
  import PopupNew from "@/components/Milestone/PopupNew";
  import ProcessChart from "@/components/Milestone/ProcessChart";
  import MilestoneOverview from "@/components/Milestone/MilestoneOverview";
  import MilestoneTable from "@/components/Milestone/MilestoneTable";

  export default ({
    components: {
      MilestoneOverview,
      ProcessChart,
      PopupSetup,
      PopupNew,
      MilestoneTable,
    },
    computed: {
      ...mapGetters([
        'loginUser',
        'page',
        'apps',
        'config',
        'notice',
        'milestoneListData',
      ]),
    },
    methods: {
      getMoreList() {
        this.$store.dispatch('GET_MILESTONE_LIST_DATA', this.milestoneListData[this.milestoneListData.length - 1].key - 10);
      },
      rowsChange(rows) {
        const selectItem = rows[rows.length - 1];

        this.milestoneListData.forEach((milestone) => {
          if (milestone.key === selectItem && milestone.timeLine.length === 0) {
            const formData = new FormData();

            formData.append('app', this.page.appId);
            formData.append('key', rows[rows.length - 1]);

            this.$store.dispatch('GET_MILESTONE_LOG_DATA', formData);
          }
        });
      },

      showAppSetup() {
        this.visibleAppSetupModal = true;
      },
      hideAppSetup() {
        this.visibleAppSetupModal = false;
      },
      showNewMilestoneModal() {
        this.visibleNewMilestoneModal = true;
      },
      hideNewMilestoneModal() {
        this.visibleNewMilestoneModal = false;
      },
    },
    data() {
      return {
        name: 'Milestone',
        visibleNewMilestoneModal: false,
        visibleAppSetupModal: false,
        bodyStyle: {padding: 0,},
        expandedRowKeys: [],
        milestoneColumns: [
          {
            title: 'version',
            dataIndex: 'key',
            scopedSlots: { customRender: 'key' },
            // width: 100,
          },
          {
            title: 'STATUS',
            dataIndex: 'status',
            class: 'font-semibold text-muted text-sm',
            // width: 100,
          },
          {
            title: 'TITLE',
            dataIndex: 'title',
            // scopedSlots: { customRender: 'title' },

          },
          {
            title: 'TASK',
            dataIndex: 'tasks',
            scopedSlots: { customRender: 'tasks' },
            // width: 110,
          },
          // {
          //   title: 'xxx',
          //   dataIndex: 'lastIndex',
          //   scopedSlots: { customRender: 'lastIndex' },
          //   width: 100,
          // },

          // {
          //   title: '기획',
          //   dataIndex: 'version',
          //   scopedSlots: { customRender: 'version' },
          //   width: 80,
          // },
          {
            title: 'TYPE',
            dataIndex: 'type',
            // scopedSlots: { customRender: 'type' },
            // width: 110,
          },
          {
            title: 'MARKET',
            dataIndex: 'market',
            scopedSlots: { customRender: 'market' },
            // width: 110,
          },
          // {
          //   title: 'MEMBERS',
          //   dataIndex: 'members',
          //   scopedSlots: { customRender: 'members' },
          // },
          {
            title: 'DEPLOY',
            dataIndex: 'target',
            class: 'font-semibold text-muted text-sm',
            // width: 100,
          },
          // {
          //   title: 'COMPLETION',
          //   scopedSlots: { customRender: 'completion' },
          //   dataIndex: 'completion',
          // },
          {
            title: '참여',
            scopedSlots: { customRender: 'editBtn' },
            // width: 100,
          },
        ],
      }
    },
  })

</script>

<style lang="scss">
  //.new-milestone {
  //  margin: 0;
  //  padding: 0;
  //
  //  li {
  //    padding-bottom: 1rem;
  //
  //    .new-milestone-title {
  //      padding-bottom: .5rem;
  //    }
  //  }
  //}
</style>

<template>
  <a-modal v-if="{}.hasOwnProperty.call(apps, page.appId)"
           v-model="isShow"
           :title="`${apps[page.appId].full_name} 게임 설정`"
           @ok="submitAppSetup"
           @cancel="closedEvent">
    <h6>Slack</h6>
    <ul class="new-milestone">
      <li>
        <div class="" slot="label">팀 슬랙 체널주소 <a-tooltip title="슬랙 화면의 팀 이름 오른쪽 화살표 클릭 후 열린 팝업 최하단의 Channel ID: XXXXXXXXXXX 를 입력해 주세요. "><a-icon type="question-circle-o" /></a-tooltip></div>
        <p>
          슬랙 화면의 팀 이름 오른쪽 화살표 클릭 후 열린 팝업 최하단의 Channel ID: XXXXXXXXXXX 를 입력해 주세요. (영대문자 조합)
        </p>
        <a-input v-model="gameSetupForm.slack_id" placeholder="XXXXXXXXXXX"></a-input>
      </li>
    </ul>
    <h6>NAS</h6>
    <ul class="new-milestone">
      <li>
        <div class="" slot="label">NAS 주소 <a-tooltip title="NAS 주소를 입력해 주세요."><a-icon type="question-circle-o" /></a-tooltip></div>
        <p>
          해당 Domain에서 사용할 NAS 의 URL을 입력해 주세요.
        </p>
        <a-input v-model="gameSetupForm.nas_url">
          <a-icon slot="prefix" type="link" />
        </a-input>
      </li>
    </ul>
  </a-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: [
    'visibleAppSetupModal'
  ],
  computed: {
    ...mapGetters([
      'loginUser',
      'page',
      'apps',
      'config',
      'notice',
      'milestoneListData',
    ]),
  },
  mounted() {
    this.setGameSetupForm();
  },
  watch: {
    milestoneListData() {
      this.setGameSetupForm();
    },
    visibleAppSetupModal(value) {
      this.isShow = value
    }
  },
  methods: {
    setGameSetupForm() {
      const gameSetupForm = {
        slack_id: '',
        nas_url: ''
      };

      if (this.apps[this.page.appId] && Object.keys(this.apps[this.page.appId].liveOps).length > 0) {
        const orgLiveOps = this.apps[this.page.appId].liveOps;

        gameSetupForm.slack_id = orgLiveOps.slack_id;
        gameSetupForm.nas_url = orgLiveOps.nas_url;
      }

      this.gameSetupForm = gameSetupForm;
    },
    submitAppSetup() {
      const formData = new FormData();

      formData.append('app', this.page.appId);
      formData.append('slack_id', this.gameSetupForm.slack_id);
      formData.append('nas_url', this.gameSetupForm.nas_url);

      this.$store.dispatch('SUBMIT_APP_SETUP', formData);
      this.$emit('hideAppSetup', false)
    },
    closedEvent() {
      this.$emit('hideAppSetup', false)
    }
  },
  data() {
    return {
      name: 'PopupSetup',
      isShow: false,
      gameSetupForm: {},
    };
  }
}
</script>

<style scoped>

</style>

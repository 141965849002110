import base from './spec/base';

export default {
  base,
  isLoading: false,
  loginUser: {},
  page: {
    appId: null,
    milestoneId: null,
  },
  apps: {},
  config: {},
  milestoneListData: [],
  milestoneOverviewData: [],
  milestoneBoardData: {},
  notice: {},
  message: {
    toast: {},
    alert: {},
    confirm: {},
    notification: {},
  },
};

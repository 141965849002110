<template>
  <div v-if="{}.hasOwnProperty.call(milestoneBoardData.step, stepId)">

    <BoardHeader :header="header" :stepId="stepId"/>
    <div>
      원화(컨셉아트) 요소
    </div>
    <a-row type="flex" :gutter="24">
      <a-col :span="24" :md="getLayoutValue(stepId).boardWidth" class="mb-24">
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="bodyStyle">
          <a-list item-layout="horizontal" :data-source="milestoneBoardData.step[stepId].history">

            <a-list-item v-if="getHistoryVisibility(item)" slot="renderItem" slot-scope="item">
              <div slot="actions" style="cursor: default;">
                <template v-if="item.approve">
                  <a v-if="item.taskUrl && item.taskUrl.length > 0"
                    :href="item.taskUrl"
                    target="_blank"
                    rel="noreferrer noopener"
                    :style="getSameVersion(item) ? 
                    ' color: #1890FF; cursor: default;' 
                    : 'cursor: default;'">
                    <a-button type="link" icon="link" style="float:right">
                      작업 완료
                    </a-button>
                  </a>
                </template>
              </div>
              <a-list-item-meta :description="item.description">
                <div slot="title">
                  <strong :style="getSameVersion(item) ? 'color: red' : ''">#{{ item.version }}</strong>

                  <span class="board-dt">{{item.dt}}</span>
                </div>
                <a-avatar slot="avatar">{{item.milestone_version}}</a-avatar>
              </a-list-item-meta>

            </a-list-item>

            <div v-if="milestoneBoardData.step[stepId].history.length > 1" slot="footer">
              <a-button slot="actions" type="" size="small" @click="toggleShowConfirmOnly(stepId)">
                {{showConfirmOnly ? '승인된 것만 보기' : '모두 보기'}}
              </a-button>
            </div>
          </a-list>
        </a-card>
      </a-col>
      <a-col v-if="getLayoutValue(stepId).actionVisibility" :span="24" :md="8" class="mb-24">
        <!-- 기획 ----------------------------------------------->
        <DeployDesignConceptArt v-if="loginUser.permission[page.appId].includes('design')"/>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import DeployDesignConceptArt from "@/components/ActionForm/DeployDesignConceptArt";
import BoardHeader from "@/components/Step/Parts/BoardHeader";

import getStepMembers from '@/mixins/getStepMembers';

export default ({
  mixins: [
    getStepMembers,
  ],
  components: {
    BoardHeader,
    DeployDesignConceptArt,
  },
  computed: {
    ...mapGetters([
      'page',
      'loginUser',
      'milestoneBoardData',
      'apps',
      'config',
    ]),
  },
  mounted() {
      this.setCurrentAction();
    },
    watch: {
      milestoneBoardData() {
        this.setCurrentAction();
      }
    },
  methods: {
    setCurrentAction() {
      // #region TaskMap
      let result = {};

      this.milestoneBoardData.tasks.forEach((task) => {
        task.children.forEach((taskChild) => {
          result[taskChild.key] = taskChild.title;
        })
      })

      this.taskMap = result;
      // #endregion
    },
    getSameVersion(item) {
      const isSameTarget =  this.milestoneBoardData.target === item.env;
      const isSameVersion = this.milestoneBoardData.version === item.milestone_version;

      return isSameTarget && isSameVersion;
    },
    getHistoryVisibility(item) {
      return (this.milestoneBoardData.step[this.stepId].history[0].version === item.version || this.showConfirmOnly) ? true : false;
    },

    checkDownloadable(stepId, item) {
      let hasAuthority = false;

      this.config.step[stepId].authority.forEach((authority) => {
        if (this.loginUser.permission[this.page.appId].includes(authority) && this.milestoneBoardData.status !== 'close') {
          hasAuthority = true;
        } else if (item.approve && (this.milestoneBoardData.step[this.stepId].history[0].version === item.version)) {
          hasAuthority = true;
        }
      });
      return hasAuthority;
    },

    toggleShowConfirmOnly() {
      this.showConfirmOnly = !this.showConfirmOnly;
    },

  },
  data() {
    return {
      stepId: 'concept_art',
      header: '원화(컨셉아트)',
      showConfirmOnly: false,
      taskMap: {},
      bodyStyle: { paddingTop: 0, paddingBottom: '16px' },
    };
  },
})

</script>

<style lang="scss" scoped>
  .board-dt {
    font-weight: 100;
    padding-left: .5rem;
    color: #999;
    font-size: .7rem
  }
</style>

<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>

  export default ({
    watch: {
      $route() {
        this.$store.dispatch('ACTION_PAGE_TRIGGER');
      },
    },
    computed: {
      // Sets components name based on current route's specified layout, defaults to
      // <layout-default></layout-default> component.
      layout() {
        return "layout-" + ( this.$route.meta.layout || "default" ).toLowerCase() ;
      }
    },
    created() {
      this.$store.dispatch('GET_LOGIN_USER_DATA');
    },
  })

</script>

<style lang="scss">
</style>
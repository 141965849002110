<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="bodyStyle">
    <br>
    <a-table v-if="milestoneListData.length > 0"
             :class="name"
             :columns="milestoneColumns"
             :data-source="milestoneListData"
             :pagination="false"
             :expanded-row-keys.sync="expandedRowKeys"
             @expandedRowsChange="rowsChange">
      <div slot="expandedRowRender" class="expandedRowRender" slot-scope="record" :key="`_${record.title}`">
        <h5>작업 내용</h5>
        <ul>
          <li v-for="(task, index) in record.tasks" :key="index" >
            <div v-if="task.children.length === 0">{{task.title}}  ( {{ task.children.length }} )</div>
            <div v-else>
              <span>{{task.title}} ( {{ task.children.length }} ) </span>
              <ul>
                <li v-for="(children, childrenIndex) in task.children" :key="childrenIndex">
                  {{children.title}}
                </li>
              </ul>
            </div>

          </li>
        </ul>
        <h5>history</h5>
        <br>
        <a-timeline>
          <a-timeline-item v-for="(item, index) in record.timeLine" :key="index" color="green">
            <span v-if="item.user_id">{{item.user_name}}({{item.user_id}})님이 {{item.msg}}</span>
            <span v-else>{{item.msg}}</span>
            <br>
            <span style="opacity: 0.5">{{item.date}}</span>
          </a-timeline-item>
          <template #pendingDot> </template>
        </a-timeline>
      </div>

      <template slot="key" slot-scope="key">
        <a-badge :dot="notice[page.appId].milestone[key] && notice[page.appId].milestone[key].hasUpdate">{{key}}</a-badge>
      </template>

      <template slot="status" slot-scope="status" >
        <span :style="status === 'create' ? 'color: red' : 'color: green'">{{status}}</span>
      </template>

      <template slot="version" slot-scope="version">
        {{version.split('.')[1]}}
      </template>

      <template slot="tasks" slot-scope="tasks">
        {{getTaskLength(tasks)}}개의 기획 반영
      </template>

      <template slot="market" slot-scope="market">
        <ul style="margin: 0; padding: 0; list-style: none" >
          <li v-for="(currentMarket, key) in market" :key="key">
            <div :style="currentMarket.isConfirm ? '' : 'opacity: 0.3;'">
              <span :style="currentMarket.approve ? 'color: blue' : 'color: #999'">{{key}}</span>
            </div>
          </li>
        </ul>
      </template>

      <template slot="editBtn" slot-scope="row">
        <router-link :to="`/${page.appId}/${row.key}`" class="nav-link" @click="e => e.preventDefault()">
          <a-button size="small" icon="edit" />
        </router-link>
      </template>
    </a-table>

    <div v-if="milestoneListData.length > 0" style="text-align: center; margin: 20px 0;">
      <a-button size="small" icon="edit" @click="getMoreList" >더보기 </a-button>
    </div>
  </a-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "MilestoneTable.vue",
  computed: {
    ...mapGetters([
      'loginUser',
      'page',
      'apps',
      'config',
      'notice',
      'milestoneListData',
    ]),
  },
  methods: {
    getMoreList() {
      this.$store.dispatch('GET_MILESTONE_LIST_DATA', this.milestoneListData[this.milestoneListData.length - 1].key - 10);
    },
    getTaskLength(tasks) {
      let taskLength = 0

      tasks.forEach((task) => {
        taskLength = taskLength + task.children.length
      })
      return taskLength
    },
    rowsChange(rows) {
      const selectItem = rows[rows.length - 1];

      this.milestoneListData.forEach((milestone) => {
        if (milestone.key === selectItem && milestone.timeLine.length === 0) {
          const formData = new FormData();

          formData.append('app', this.page.appId);
          formData.append('key', rows[rows.length - 1]);

          this.$store.dispatch('GET_MILESTONE_LOG_DATA', formData);
        }
      });
    },
  },
  data() {
    return {
      name: 'MilestoneTable',
      visibleNewMilestoneModal: false,
      visibleAppSetupModal: false,
      bodyStyle: {padding: 0,},
      expandedRowKeys: [],
      milestoneColumns: [
        {
          title: 'version',
          dataIndex: 'key',
          scopedSlots: { customRender: 'key' },
          // width: 100,
        },
        {
          title: 'date',
          dataIndex: 'created',
          // scopedSlots: { customRender: 'create' },
          class: 'font-semibold text-muted text-sm',
          // width: 100,
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          class: 'font-semibold text-muted text-sm',
          // width: 100,
        },
        {
          title: 'TITLE',
          dataIndex: 'title',
          // scopedSlots: { customRender: 'title' },

        },
        {
          title: 'TASK',
          dataIndex: 'tasks',
          scopedSlots: { customRender: 'tasks' },
          // width: 110,
        },
        {
          title: '참여',
          scopedSlots: { customRender: 'editBtn' },
          // width: 100,
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.MilestoneTable {
  .ant-table-content {
    th ,td {
      padding: 10px 0;
      text-align: center;
      .expandedRowRender {
        text-align: left;
      }
    }
  }
}
</style>

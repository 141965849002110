<template>
  <div v-if="{}.hasOwnProperty.call(milestoneBoardData.step, stepId)">
    <BoardHeader :header="header" :stepId="stepId"/>

    <a-row type="flex" :gutter="24">
      <a-col :span="24" :md="getLayoutValue(stepId).boardWidth" class="mb-24">
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="bodyStyle">
          <a-list item-layout="horizontal" :data-source="computedData">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-list-item-meta >
                <div slot="title">
                  <template v-if="item.approve">
                    <a slot="actions" v-if="item.link !== ''" :href="item.link" target="_blank">최종 보고서 링크</a>
                  </template>
                  <template v-else style="color: black">
                    <span>마일스톤이 최종완료된 후 최종보고서가 업로드됩니다.</span>
                  </template>
                </div>
                <div slot="description">
                  <template v-if="milestoneBoardData.step.final_report.history.length > 0">
                    <div>
                      출시일: <strong>{{milestoneBoardData.step.final_report.history[0].release_dt}}</strong>
                      <br>
                      제작 기간: {{getDayDiff(milestoneBoardData.created, milestoneBoardData.step.final_report.history[0].dt)}}일
                      <ul>
                        <li>
                          {{milestoneBoardData.created}} ~ {{milestoneBoardData.step.final_report.history[0].dt}}
                        </li>
                      </ul>
                      제작 인원: 관련자({{milestoneBoardData.members.length}})명, 참여자({{milestoneBoardData.workers.length}})명
                      <ul>
                        <li>
                          관련자: <span v-for="(member, index) in milestoneBoardData.members" :key="index">{{member.name}} </span>
                        </li>
                        <li>
                          MM: <span v-for="(member, index) in milestoneBoardData.workers" :key="index">{{member.name}} </span>
                        </li>
                      </ul>
                      작업 크기: {{milestoneBoardData.step.final_report.history[0].volume}}
                    </div>
                  </template>
                  <template v-else>
                    <div>
                      제작 기간: 개발 진행중
                      <ul>
                        <li>
                          {{milestoneBoardData.created}} ~
                        </li>
                      </ul>
                      제작 인원: 관련자({{milestoneBoardData.members.length}})명
                      <ul>
                        <li>
                          관련자: <span v-for="(member, index) in milestoneBoardData.members" :key="index">{{member.name}} </span>
                        </li>
                      </ul>

                    </div>
                  </template>

                </div>
                <a-avatar slot="avatar">{{milestoneBoardData.version}}</a-avatar>
              </a-list-item-meta>

            </a-list-item>
          </a-list>

        </a-card>
      </a-col>
      <a-col v-if="getLayoutValue(stepId).actionVisibility" :span="24" :md="8" class="mb-24">
        <UploadFinalReport v-if="loginUser.permission[page.appId].includes('upload_final_report')"/>
      </a-col>
    </a-row>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex';
  import UploadFinalReport from "@/components/ActionForm/UploadFinalReport";
  import BoardHeader from "@/components/Step/Parts/BoardHeader";

  // import getStepMembers from '@/mixins/getStepMembers';

  export default ({
    mixins: [
      // getStepMembers,
    ],
    components: {
      BoardHeader,
      UploadFinalReport,
    },
    computed: {
      ...mapGetters([
        'page',
        'loginUser',
        'milestoneBoardData',
        'apps',
        'config',
      ]),
      computedData() {
        const reviewData = [];

        if (this.milestoneBoardData.step[this.stepId].history.length > 0) {
          reviewData.push(this.milestoneBoardData.step[this.stepId].history[0])
        } else {
          reviewData.push({
            milestone_version: '71.1',
            version: '3',
            dt: '2023-10-10',
            link: 'https://docs.google.com/,',
            type: '',
            env: null,
            description: '',
            approve: false,
            isConfirm: true,
            isConfirmMaster: true,
            volume: 5
          })
        }
        return reviewData;
      },
    },
    watch: {
    },
    methods: {
      getDayDiff(d1, d2) {
        const date1 = new Date(d1);
        const date2 = new Date(d2);

        const diffDate = date1.getTime() - date2.getTime();

        return Math.floor(Math.abs(diffDate / (1000 * 60 * 60 * 24 * 365)));
      },
      getLayoutValue() {
        const layoutValue = {
          boardWidth: 24,
          actionVisibility: false,
        };

        this.config.step[this.stepId].authority.forEach((authority) => {
          if (this.loginUser.permission[this.page.appId].includes(authority)) {
            layoutValue.actionVisibility = true;
            layoutValue.boardWidth= 16;
          }
        });
        return layoutValue;
      },
    },
    data() {
      return {
        stepId: 'final_report',
        header: '최종 보고서',
        showConfirmOnly: false,
        bodyStyle: { paddingTop: 0, paddingBottom: '16px' },
        options: [],
        bringTheStep: ['final_report'],
      };
    },
  })

</script>

const setBase = () => {
  // --> todo : local test code
  if (window.__AUTHORITY_SERVICE_ENV__.base.service === '##service') {
    // const service = location.hostname.split('.')[0];
    const service = 'authority';
    window.__AUTHORITY_SERVICE_ENV__.base.company = '';
    window.__AUTHORITY_SERVICE_ENV__.base.service = service;
    window.__AUTHORITY_SERVICE_ENV__.base.baseUrl = `http://${location.hostname}:9081`;
  }
  if (window.__AUTHORITY_SERVICE_ENV__.base.env === '##env') {
    const key = 'development';
    const env = {
      production: 'production',
      development: 'development',
    };
    window.__AUTHORITY_SERVICE_ENV__.base.env = env[key];
  }
  // <-- todo : local test code

  return window.__AUTHORITY_SERVICE_ENV__.base;
};

export default setBase();

<template>
	<div :class="stepId">
    <h6 class="font-semibold m-0">AI 이미지 추출 관련 직군</h6>
		<br>
		<div v-if="milestoneBoardData.available_steps.includes(stepId)">
			<div>
				<template>
					<strong>완료한 작업 선택</strong>&nbsp;
					<a-tooltip title="완료된 작업을 중분류 단위로 체크해 주세요. 각 작업마다 URL이 필요하며, URL은 https://wemaker로 시작하여야만 합니다."><a-icon type="question-circle-o" /></a-tooltip>
					<br>
					<a-tree v-model="selectedTasks" checkable defaultExpandAll>
            <a-tree-node
              v-for="(task) in milestoneBoardData.tasks"
              :hidden="stepRoleMap[task.key].length === 0"
              :key="task.key"
              :title="task.title">
              <a-tree-node
                v-for="(child) in task.children"
                :key="child.key"
                :disableCheckbox="!(stepRoleMap[task.key] && stepRoleMap[task.key].includes(child.key))"
                :hidden="!(stepRoleMap[task.key] && stepRoleMap[task.key].includes(child.key))"
                style="height: 5em;">
                <template #title>
                  <div>{{child.title}}</div>
                  <a-input
                    :disabled="!selectedTasks.includes(child.key)"
                    placeholder="작업의 링크를 입력해 주세요."
                    type="url"
                    v-model="child.stepRoleData.wemakerUrl">
                    <a-icon slot="prefix" type="link"/>
                  </a-input>
                </template>
              </a-tree-node>
            </a-tree-node>
					</a-tree>
				</template>
			</div>
      <br>
			<div>
        <strong>디자인 노트 :</strong>&nbsp;
        <a-tooltip title="디자인한 부분의 전체적인 사항을 팀원에게 설명해 주세요."><a-icon type="question-circle-o" /></a-tooltip>
        <a-textarea v-model="description"
                    placeholder="디자인한 내용을 간략하게 기술해 주세요."
                    :auto-size="{ minRows: 2, maxRows: 10 }"/>
        <br>
        <br>
        <a-button type="primary" :disabled="isLoading" size="small" @click="onSubmit">📦 제출하기</a-button>
			</div>
		</div>
		<div v-else>
			기획서가 승인되면 승인된 기획서를 기반으로 디자인해 주세요.
			<br>
			승인된 기획서가 없어 지금은 작업 하실 수 없습니다.
		</div>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import { SUBMIT_DEPLOY_DESIGN_AIIMAGE } from '@/store/actions-types.js';

export default ({
  props: [],
  computed: {
    ...mapGetters([
      'page',
      'config',
      'loginUser',
      'milestoneBoardData',
      'apps',
    ]),
    //Key : TaskKey
    //Value : Children key that current step is in steprole
    stepRoleMap() {
      const result = {};

      this.milestoneBoardData.tasks.forEach((task) => {
        result[task.key] = task.children
          .filter(child => this.isInStepRole(1, child.stepRoles))
          .map(child => child.key);
      });

      return result;
    },
  },
  mounted() {
    this.setCurrentAction();
  },
  watch: {
    milestoneBoardData() {
      this.setCurrentAction();
    }
  },
  methods: {
    setCurrentAction() {
      let newTaskMapKeys = new Set();

      this.milestoneBoardData.tasks.forEach((task) => {
        task.children.forEach((taskChild) => {
          newTaskMapKeys.add(taskChild.key);
        })
      })

      this.taskMapKeys = Array.from(newTaskMapKeys);

      let selectedTasks = [];
      const history = this.milestoneBoardData.step[this.stepId].history;

      if (history.length > 0) {
        selectedTasks = history[0].selectedTasks.filter((selectedTask) => {
          return this.taskMapKeys.includes(selectedTask);
        });
      }
      this.isLoading = false;

      const taskOutOfStepRoles = this.milestoneBoardData.tasks.reduce((result, task) => {
        let stepRoles = task.children.filter(child => !this.isInStepRole(1, child.stepRoles));
        return result.concat(stepRoles);
      }, [])
        .map((stepRoles) => stepRoles.key);

      this.selectedTasks = [...new Set([...selectedTasks, ...taskOutOfStepRoles])];
    },
    openNotification(description) {
      this.$notification.open({
        message: '확인해 주세요.',
        description,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    },
    isInStepRole(sequenceLevel, stepRoles) {
      const isInSequence = this.milestoneBoardData.sequence[sequenceLevel].includes(this.stepId);

      return isInSequence && stepRoles.includes(this.stepId);
    },
    onSubmit() {
      const milestoneTasks = this.milestoneBoardData.tasks;
      const allTaskCount = Object.values(milestoneTasks)
        .reduce((acc, curr) => acc + curr.children.length, 0);
      const haveToDoneCount = Object.values(this.stepRoleMap)
        .reduce((acc, curr) => acc + curr.length, 0);
      const doneCount = this.selectedTasks.filter(element => typeof element === 'string').length;
      const formData = new FormData();
      let description = this.description;

      this.isLoading = true;
      if (description === '') {
        this.openNotification('빠진 부분이 있는지 다시 한번 확인해 주세요.');
        this.isLoading = false;
        return;
      }
      if ((allTaskCount - doneCount >= haveToDoneCount )) {
        this.openNotification('완료된 디자인을 하나 이상 선택해 주세요.');
        this.isLoading = false;
        return;
      }

      let isTaskUrlNullOrEmpty = false;
      let isTaskUrlNotValid = false;

      milestoneTasks.forEach((task) => {
        const taskChildren = task.children;

        taskChildren.forEach((taskChild) => {

          if (!this.selectedTasks.includes(taskChild.key)) {
            return;
          }

          if (!this.isInStepRole(1, taskChild.stepRoles)) {
            return;
          }

          if ( !taskChild.stepRoleData
            || taskChild.stepRoleData.wemakerUrl === null
            || taskChild.stepRoleData.wemakerUrl === "") {
            isTaskUrlNullOrEmpty = true;
            return;
          }

          let lowerTaskUrl = taskChild.stepRoleData.wemakerUrl.toLowerCase();

          if (lowerTaskUrl.startsWith('https://wemaker') === false) {
            isTaskUrlNotValid = true;
            return;
          }
        })
      })

      if (isTaskUrlNullOrEmpty) {
        this.openNotification('체크된 작업의 URL중 비어있는 것이 있습니다.');
        this.isLoading = false;
        return;
      }

      if (isTaskUrlNotValid) {
        this.openNotification('체크된 작업의 URL중 https://wemaker로 시작하지 않는 것이 있습니다.');
        this.isLoading = false;
        return;
      }

      formData.append('app', this.page.appId);
      formData.append('key', this.page.milestoneId);
      formData.append('milestone_version', this.milestoneBoardData.version);
      formData.append('description', description);
      formData.append('env', this.milestoneBoardData.target);
      formData.append('tasks', JSON.stringify(milestoneTasks));
      formData.append('selectedTasks', JSON.stringify(this.selectedTasks));
      formData.append('step', this.stepId);

      this.$store.dispatch(SUBMIT_DEPLOY_DESIGN_AIIMAGE, formData);
    },

  },
  data() {
    return {
      stepId: 'ai_image',
      description: '',
      selectedTasks: [],
      taskMapKeys : [],
      isLoading: false,
    }
  },
})

</script>

<style lang="scss" scoped>
	.deploy_server {
		margin-bottom: 2rem;
	}
</style>

<template>
  <a-card :bordered="false" class="header-solid h-full">
    overview
    <!--  <MilestoneChart :milestoneBoardData="this.milestoneBoardData"/>-->
  </a-card>
</template>

<script>

  import { mapGetters } from 'vuex';

  // import MilestoneChart from "@/components/Milestone/MilestoneChart";

  export default ({
    components: {
      // MilestoneChart
    },
    computed: {
      ...mapGetters([
        'loginUser',
        'page',
        'apps',
        'config',
        'notice',
        'milestoneListData',
      ]),
    },
    mounted() {
    },
    watch: {
      milestoneListData() {
      },
    },
    methods: {
    },
    data() {
      return {
        name: 'Overview',
      }
    },
  })

</script>

<style lang="scss">

</style>

<template>
  <a-card v-if="milestoneListData.length > 0" :bordered="false" class="header-solid h-full" :bodyStyle="bodyStyle">
    <template #title>
      <h6>TimeLine</h6>
      <p><span class="text-success">완료</span>된 마일스톤만 표기 됩니다.</p>
    </template>
    <v-chart class="chart" :option="option" autoresize :style="`height: ${option.yAxis.max * 80}px`"/>
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex';

import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart, BarChart, LineChart, LinesChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';

use([
  CanvasRenderer,
  LinesChart,
  PieChart,
  BarChart,
  LineChart,
  TitleComponent,
  ToolboxComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
]);

export default ({
  components: {
    VChart,
  },
  computed: {
    ...mapGetters([
      'loginUser',
      'page',
      'apps',
      'config',
      'notice',
      'milestoneListData',
    ]),
  },
  mounted() {
    this.makeChartData();
  },
  watch: {
    milestoneListData() {
      this.makeChartData();
    }
  },
  methods: {
    makeChartData() {
      const series = [];
      let lineNum = 1

      if (this.milestoneListData.length > 0) {
        const firstDate = this.milestoneListData[this.milestoneListData.length - 1].created

        this.milestoneListData.forEach((milestone) => {
          if (milestone.final_report !== '') {
            const created = milestone.created
            const release = milestone.release_dt
            lineNum++
            const data = this.getDatesStartToLast(new Date(created), new Date(release), lineNum)
            const seriesObj = {
              name: `${milestone.version} ( ${milestone.title} )`,
              type: 'line',
              lineStyle: {
                width: milestone.volume * 10
              },
              showSymbol: true,
              data: data.length === 0 ? [[created, lineNum]] : data
            };
            series.push(seriesObj)
          }
        })
        this.option.xAxis.data = this.getDatesStartToLast(new Date(firstDate), new Date())
        this.option.yAxis.max = lineNum
        this.option.series = series
      }
    },
    getDatesStartToLast(startDate, lastDate, lineNum) {
      const result = [];

      while (startDate <= lastDate) {
        const starDt = startDate.toISOString().split('T')[0]
        const dt = lineNum ? [starDt, lineNum] : starDt

        result.push(dt);
        startDate.setDate(startDate.getDate() + 1);
      }
      return result;
    },
  },
  data() {
    return {
      name: 'processChart',
      bodyStyle: {padding: 0,},
      option: {
        tooltip: {
          type: 'hideTip',
          trigger: 'axis',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          splitLine: {
            show: true
          },
          axisLabel: {
            margin: 30,
            fontSize: 16
          },
          splitArea: {
            show: true
          },
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 0,
          },
          max: 4,
        },
        series: []
      },
    };
  },
});
</script>

<style scoped>
/*.chart {*/
/*  height: 300px;*/
/*}*/
/*canvas {*/
/*  max-height: 180px;*/
/*  max-width: 320px;*/
/*}*/
</style>

<template>
  <div>
    <div>
      <template  v-if="workers.length === 0">
        <a-list item-layout="horizontal">
          <a-list-item>
            <a-list-item-meta description="이 업무를 진행 할 사람이 지정해 주세요.">
              <h5 slot="title">{{header}}</h5>
              <a-avatar slot="avatar" icon="exclamation" style="background-color: red"/>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </template>
      <template v-else>
        <h5>{{header}} </h5><a v-if="link" :href="link" target="_blank">시트 바로가기</a>
      </template>
    </div>
    <template v-if="milestoneBoardData && {}.hasOwnProperty.call(milestoneBoardData, 'final_report') && milestoneBoardData.final_report === ''">
      <template v-if="loginUser.permission[milestoneBoardData.app].includes('confirm_game')">
        <a-select
          :disabled="isLoading"
          v-if="members.length > 0"
          v-model="tempWorkers"
          style="width: 66%"
          mode="multiple"
          :options="members"
          @change="handleChange"
        ></a-select>
        <br><br>
      </template>
    </template>
    <template>
      <span v-for="(worker, index) in workers" :key="`${index}_${worker.id}`" >
        <a target="_blank"
          :href="`https://authority.stzinfra.net/member/${worker.id}`">
          <a-avatar :src="worker.img_url"/>&nbsp;&nbsp;
        </a>
      </span>
    </template>

  </div>
</template>

<script>

  import { mapGetters } from 'vuex';

  export default ({
    props: [
      'header',
      'stepId',
      'link',
    ],
    computed: {
      ...mapGetters([
        'loginUser',
        'milestoneBoardData',
        'config',
      ]),
    },
    watch: {
      milestoneBoardData() {
        this.getMembers()
      }
    },
    mounted() {
      this.getMembers()
    },
    methods: {
      getMembers() {
        const tempMembers = [];
        const stepObj = this.milestoneBoardData.step[this.stepId]
        const stepMembers = stepObj.members;
        const stepWorkers = stepObj.workers;

        this.isLoading = false;
        stepWorkers.forEach((worker) => {
          if (!this.tempWorkers.includes(worker.id)) {
            this.tempWorkers.push(worker.id)
          }
        })
        stepMembers.forEach((member) => {
          tempMembers.push({
            label: member.name,
            value: member.id,
          })
        })
        this.members = tempMembers
        this.workers = stepWorkers
      },
      openNotification(description) {
        this.$notification.open({
          message: '확인해 주세요.',
          description,
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      },
      handleChange(value) {
        const formData = new FormData();

        this.isLoading = true;
        if (value.length === 0) {
          const tempWorkers = [];
          this.members.forEach((member) => {
            tempWorkers.push(member.value)
          })
          this.tempWorkers = tempWorkers
          this.openNotification('작업자는 최소 한명 이상이어야 합니다. ');
        } else {
          this.tempWorkers = value
        }
        formData.append('app', this.milestoneBoardData.app);
        formData.append('key', this.milestoneBoardData.key);
        formData.append('step', this.stepId);
        formData.append('workers', this.tempWorkers.join());

        this.$store.dispatch('SUBMIT_CHANGE_WORKERS', formData);
      },
    },
    data() {
      return {
        isLoading: false,
        members: [],
        workers: [],
        tempWorkers: [],
      };
    },
  })

</script>

<template>
	<div :class="stepId">
		<a-row type="flex" align="middle">
			<a-col :span="24" :md="24">
				<h6 class="font-semibold m-0">기획</h6>
			</a-col>
		</a-row>
		<br>
		<div v-if="milestoneBoardData.available_steps.includes(stepId)">
			<div v-for="(task, taskIndex) in tasks" :key="taskIndex">
				<span>- {{task.title}}</span>&nbsp;
				<a-tooltip :title="`${task.title} 기획이 있다면 내용을 입력하고 글쓰기 버튼을 클릭해 주세요. 기획 내용이 없으면 빈칸으로 두세요.`"><a-icon type="question-circle-o" /></a-tooltip>
				<template>
					<a-input-search v-model="task.temp" size="small" :placeholder="`적용될 ${task.title}의 내용을 주제별로 입력해 주세요`" @search="onAddTaskChildren(taskIndex)">
						<a-button slot="enterButton" size="small" icon="edit" />
					</a-input-search>
					<br>
				</template>
				<div v-for="(child, childIndex) in task.children" :key="childIndex">
					<strong>{{child.title}}</strong>
					<a-button type="link" size="small" shape="circle" icon="delete" @click="onDeleteTaskChildren(child.title, taskIndex, childIndex)" />
          <div style="flex: auto;">
            <a-tag
              v-for="stepRole in child.stepRoles"
              :key="`${child.key}:StepRole:${stepRole}`"
              :closable="child.stepRoles.length > 1"
              @click="removeStepRole(child, stepRole)"
              @close="removeStepRole(child, stepRole)"
              style="cursor: pointer;">
              {{ config.step[stepRole].label }}
            </a-tag>
            <a-tooltip :title="`작업 ${child.title}을 수행할 스텝들을 고르세요. 실제 작업 스텝 중, 대상 스텝에서만 이 작업이 노출됩니다.`"><a-icon type="question-circle-o" /></a-tooltip>
            <a-tooltip :title="`작업 ${child.title}을 수행할 스텝들을 기본으로 되돌립니다.`">
              <a-button type="link" size="small" shape="circle" icon="undo" @click="resetStepRole(child)" ></a-button>
            </a-tooltip>
          </div>
				</div> 
				<br>
			</div>
      <div v-if="freeTaskStepRoles.length > 0">
        <strong style="color:tomato;">
          ⚠️ 아무 작업도 수행하지 않는 이하의 스텝(들)이 있습니다.
        </strong>
        <hr/>
        <div v-for="freeTaskStepRole in freeTaskStepRoles"
          :key="`FreeTaskStepRole:[${freeTaskStepRole}]`">
          - {{ config.step[freeTaskStepRole].label }}
        </div>
        <hr/>
      </div>
      <br>
			<div>
				<strong>기획 노트 :</strong>&nbsp;
				<a-tooltip title="바뀐 부분을 팀원에게 설명해 주세요."><a-icon type="question-circle-o" /></a-tooltip>
				<a-textarea v-model="description"
										placeholder="간략히 내용을 정리해 주세요"
										:auto-size="{ minRows: 2, maxRows: 10 }"/>
			</div>
			<br>
			<div>
				<strong>기획서 링크 :</strong>&nbsp;
				<a-tooltip title="https://www.notion.so/, https://docs.google.com/, https://drive.google.com/, 중 하나를 사용 합니다. "><a-icon type="question-circle-o" /></a-tooltip>
				<a-input v-model="presentation_link" placeholder="https://">
					<a-icon slot="prefix" type="link" />
				</a-input>
			</div>
			<br>
			<a-button type="primary" :disabled="isLoading" size="small" @click="onSubmit">작성된 기획서를 상신 합니다.</a-button>
		</div>
		<div v-else>
			Milestone이 Live 단계로 접어 들어 더이상 수정 할 수 없습니다.
			<br>
			다음 Milestone에서 작업을 진행해 주세요.
		</div>
	</div>
</template>

<script>

  import { mapGetters } from 'vuex';
  import validation from '@/filters/validation';

  export default ({
    props: [],
		filter: {
      validation,
		},
    computed: {
      ...mapGetters([
        'page',
        'config',
        'loginUser',
        'milestoneBoardData',
        'apps',
      ]),
      freeTaskStepRoles() {
        const freeRoles = this.milestoneBoardData.sequence[1].filter(step =>
          !this.tasks.some(task =>
              task.children.some((taskChild) =>
                taskChild.stepRoles.includes(step))));

        return freeRoles;
      },
    },
    mounted() {
      this.setCurrentAction();
    },
		watch: {
      milestoneBoardData() {
        this.setCurrentAction();
			}
		},
		methods: {
      setCurrentAction() {
        this.isLoading = false;
        this.tasks = JSON.parse(JSON.stringify(this.milestoneBoardData.tasks));
			},
      onAddTaskChildren(taskIndex) {
        const task = this.tasks[taskIndex];
        const children = task.children;
        const child = {
          title: task.temp,
          key: `${task.key}-${children.length + 1}`,
          stepRoles: this.milestoneBoardData.sequence[1],
          stepRoleData: {
            version: 0
          },
        };

        children.push(child);
        this.tasks[taskIndex].temp = '';
      },
      onDeleteTaskChildren(childTitle, taskIndex, childIndex) {
        const returnTaskChildren = [];
        const task = this.tasks[taskIndex];
        const children = task.children;
        let key = 0;

        children.forEach((child, index) => {
          if (index !== childIndex) {
            key += 1;
            child.key = `${task.key}-${key}`;
            returnTaskChildren.push(child);
          }
        });
        this.tasks[taskIndex].children = returnTaskChildren;
      },
      removeStepRole(child, stepRole) {
        if (child.stepRoles.length <= 1) {
          this.openNotification('작업을 담당할 스텝은 최소 1개 이상 있어야 합니다.');
          return;
        }

        child.stepRoles = child.stepRoles.filter((target) => {
          return target !== stepRole;
        });
      },
      resetStepRole(child) {
        child.stepRoles = this.milestoneBoardData.sequence[1];
      },
      openNotification(description) {
        this.$notification.open({
          message: '확인해 주세요.',
          description,
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      },
      onSubmit() {
        const formData = new FormData();
        const taskCount = this.tasks.reduce((totalCount, task) => totalCount + task.children.length, 0);

        this.isLoading = true;
        if (this.description === '') {
          this.openNotification('기획 노트를 작성해 주세요.');
          this.isLoading = false;
        } else if (!taskCount) {
          this.openNotification('기획서 카테고리중 하나이상 작성하셔야 합니다.');
          this.isLoading = false;
        } else if (!validation.validDocsURL(this.presentation_link)) {
          this.openNotification('링크가 정확한지 다시 한번 확인해 주세요.');
          this.isLoading = false;
        } else {
          formData.append('app', this.page.appId);
          formData.append('key', this.page.milestoneId);
          formData.append('description', this.description);
          formData.append('url', this.presentation_link);
          formData.append('tasks', JSON.stringify(this.tasks));
          formData.append('step', this.stepId);

          this.$store.dispatch('SUBMIT_UPLOAD_PLAN', formData);
        }
      },
    },
		data() {
			return {
				stepId: 'plan',
        tasks: [],
        description: '',
        presentation_link: '',
        isLoading: false,
			}
		},
	})

</script>

<style lang="scss" scoped>
	.plan {
		margin-bottom: 2rem;
	}
</style>

<template>
  <a-modal v-if="{}.hasOwnProperty.call(apps, page.appId)"
           v-model="isShow"
           :title="`${apps[page.appId].full_name} 게임 설정`"
           @ok="submitNewMilestone"
           @cancel="closedEvent">
    <ul class="new-milestone">
      <li>
        <div class="new-milestone-title">TYPE <a-tooltip title="타입"><a-icon type="question-circle-o" /></a-tooltip></div>
        <a-select v-model="milestoneListForm.type" style="width: 100%" @change="changeMilestoneListFormType">
          <a-select-option v-for="(type, typeKey) in config.type" :key="typeKey" :value="typeKey">
            {{type.label}}
          </a-select-option>
        </a-select>
      </li>
      <li>
        <div class="new-milestone-title" slot="label">TITLE <a-tooltip title="작업 제목"><a-icon type="question-circle-o" /></a-tooltip></div>
        <a-input  v-model="milestoneListForm.title"  v-decorator="['nickname',{rules: [{ required: true, message: 'Please input your nickname!', whitespace: true }],},]"/>
      </li>
      <li>
        <div class="new-milestone-title">
          STEP
          <a-tooltip title="진행 스텝을 결정해 주세요."><a-icon type="question-circle-o" /></a-tooltip>
        </div>

        <a-checkbox-group v-model="milestoneListForm.steps" name="checkboxgroup">
          <a-row>
            <template v-for="(sequence, sequenceIndex) in config.sequence">
              <template v-for="(stepId, stepIndex) in sequence">
                <a-col v-if="getAvailableStep(stepId)" :key="`${sequenceIndex}-${stepIndex}`" :span="24">
                  <a-checkbox :value="stepId" :disabled="config.step[stepId].disabled" @change="onSelectStep(stepId)">
                    {{config.step[stepId].label}}
                  </a-checkbox>
                </a-col>
              </template>
            </template>
          </a-row>
        </a-checkbox-group>
      </li>
    </ul>
  </a-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: [
    'visibleNewMilestoneModal'
  ],
  computed: {
    ...mapGetters([
      'loginUser',
      'page',
      'apps',
      'config',
      'notice',
      'milestoneListData',
    ]),
  },
  mounted() {
    this.setCurrentAction();
  },
  watch: {
    milestoneListData() {
      this.setCurrentAction();
    },
    visibleNewMilestoneModal(value) {
      this.isShow = value
    }
  },
  methods: {
    changeMilestoneListFormType() {
      this.milestoneListForm.steps = this.resetMilestoneListFormSteps(this.milestoneListForm.type);
    },
    getAvailableStep(stepId) {
      const stepSplit = stepId.split('_');
      let returnValue = false;
      const liveOps = this.apps[this.page.appId].liveOps;

      if (Object.keys(liveOps).length > 0) {
        if (stepId === 'deploy_static_sheets') {
          if (liveOps['static_sheets'] !== '') {
            returnValue = true;
          }
        } else if (stepSplit[0] === 'build') {
          let index = 0;
          const envs = ['stage', 'live'];

          envs.forEach((env) => {
            if (liveOps.jenkins[env][stepSplit[1]] && liveOps.jenkins[env][stepSplit[1]] !== '') {
              index = index + 1;
            }
          });
          returnValue = index === envs.length;
        } else {
          returnValue = true;
        }
      }
      return returnValue;
    },
    onSelectStep(stepId) {
      if (stepId === 'deploy_server') {
        if (this.milestoneListForm.steps.includes('deploy_server')) {
          const idx = this.milestoneListForm.steps.indexOf('code_review');
          if (idx > -1) {
            this.milestoneListForm.steps.splice(idx, 1);
          }
        } else {
          if ( this.milestoneListForm.type !== 'stage' ) {
            this.milestoneListForm.steps.push('code_review');
          }
        }
      }
      if (stepId === 'qa') {
        if (this.milestoneListForm.steps.includes('qa')) {
          const idx = this.milestoneListForm.steps.indexOf('final_report');
          if (idx > -1) {
            this.milestoneListForm.steps.splice(idx, 1);
          }
        } else {
          if ( this.milestoneListForm.type !== 'stage' ) {
            this.milestoneListForm.steps.push('final_report');
          }
        }
      }
      setTimeout(() => {
            const build = ['build_appstore', 'build_playstore', 'build_onestore', 'build_galaxystore', 'build_facebookstore']
            const difference = this.milestoneListForm.steps.filter(x => build.includes(x));

            if (difference.length === 0) {
              if (this.milestoneListForm.steps.includes('upload_build_market')) {
                const idx = this.milestoneListForm.steps.indexOf('upload_build_market');
                if (idx > -1) {
                  this.milestoneListForm.steps.splice(idx, 1);
                }
              }
            } else {
              if (!this.milestoneListForm.steps.includes('upload_build_market') && this.milestoneListForm.type !== 'stage' ) {
                this.milestoneListForm.steps.push('upload_build_market');
              }
            }
          }
          , 100);
    },
    resetMilestoneListFormSteps(type) {
      const resetSteps = [];
      const orgStep = this.config.step;
      const option = {
        stage: {
          isRequire: ['plan','confirm_game', 'final_report'],
          disabled: ['plan','confirm_game', 'final_report'],
        }
      }

      if (orgStep) {
        Object.keys(orgStep).forEach((stepId) => {
          const isRequire = option[type].isRequire.includes(stepId)
          const disabled = option[type].disabled.includes(stepId)

          if (isRequire) {
            resetSteps.push(stepId);
          }
          orgStep[stepId].isRequire = isRequire
          orgStep[stepId].disabled = disabled
        });
      }
      return resetSteps;
    },
    setCurrentAction() {
      const milestoneListForm = {
        key: 0,
        version: '',
        type: 'stage',
        market: {},
        target: 'stage',
        checkpoints: [],
        title: '',
        members: [],
        tasks: [],
        final_report: '', // url
        status: 'create',
        steps: this.resetMilestoneListFormSteps('stage'),
        completed_steps: [],
        timeLine: [],
      };

      this.milestoneListForm = milestoneListForm;
    },
    setSequence() {
      const returnSequence = [];

      this.config.sequence.forEach((sequence) => {
        const steps = [];

        sequence.forEach((stepId) => {
          if (this.milestoneListForm.steps.includes(stepId)) {
            steps.push(stepId);
          }
        });
        if (steps.length > 0) {
          returnSequence.push(steps);
        }
      });
      return returnSequence;
    },
    submitNewMilestone() {
      const formData = new FormData();

      if (this.milestoneListForm.title === '') {
        this.openNotification('TITLE을 입력해 주세요.');
      } else if (this.tasks.length === 0) {
        this.openNotification('TASKS(대분류)를 하나이상 만들어 주세요.');
      } else {
        formData.append('app', this.page.appId);
        formData.append('type', this.milestoneListForm.type);
        formData.append('title', this.milestoneListForm.title);
        formData.append('sequence', JSON.stringify(this.setSequence()));
        formData.append('tasks', JSON.stringify(this.tasks));
        formData.append('steps', JSON.stringify(this.milestoneListForm.steps));

        this.$store.dispatch('SUBMIT_ADD_MILESTONE', formData);
        this.$emit('hideNewMilestoneModal', false)
      }
    },
    openNotification(description) {
      this.$notification.open({
        message: '확인해 주세요.',
        description,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    },
    closedEvent() {
      this.$emit('hideNewMilestoneModal', false)
    }
  },
  data() {
    return {
      name: 'PopupNew',
      isShow: false,
      taskInputString: '',
      milestoneListForm: {},
      sequence: [],
      tasks: [
      {
          title: '신규기능',
          key: 1,
          closed: [],
          children: [],
          isEditMode: false,
        },
        {
          title: '업데이트',
          key: 2,
          closed: [],
          children: [],
          isEditMode: false,
        },
        {
          title: '이벤트',
          key: 3,
          closed: [],
          children: [],
          isEditMode: false,
        },
        {
          title: '기타',
          key: 4,
          closed: [],
          children: [],
          isEditMode: false,
        },
      ],
    };
  }
}
</script>

<style lang="scss">
.new-milestone {
  margin: 0;
  padding: 0;

  li {
    padding-bottom: 1rem;

    .new-milestone-title {
      padding-bottom: .5rem;
    }
  }
}
</style>

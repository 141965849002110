<!-- Confirm의 Design 부문 fork입니다. 표기정도만 다릅니다.-->
<template>
  <div :class="stepId" v-if="{}.hasOwnProperty.call(milestoneBoardData.step, stepId)">

    <BoardHeader :header="header" :stepId="stepId"/>

    <a-row type="flex" :gutter="24">
      <a-col :span="24" :md="getLayoutValue(stepId).boardWidth" class="mb-24">
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="bodyStyle">
          <a-list item-layout="vertical" :data-source="[milestoneBoardData.tasks]">
            <a-list-item slot="renderItem">

              <a-list-item-meta :description="`배포 형태 : ${milestoneBoardData.type}` ">

                <div v-if="milestoneBoardData.completed_steps.includes(stepId)" slot="title">
                  <strong style="color: red">{{milestoneBoardData.title}}</strong>
                  <a-tag slot="actions" class="tag-status ant-tag-primary" style="float:right">배포 완료</a-tag>
                </div>
                <div v-else slot="title">
                  <strong>{{milestoneBoardData.title}}</strong>
                  <a-tag slot="actions" class="tag-status" style="float:right">미배포</a-tag>
                </div>
                <a-avatar slot="avatar">{{milestoneBoardData.version}}</a-avatar>
              </a-list-item-meta>

              <a-card v-if="milestoneBoardData.tasks.length > 0" :bordered="false" class="card-billing-info">
                <a-tree defaultExpandAll :selectable="false">
                  <template v-for="(task) in milestoneBoardData.tasks">
                    <template v-if="task.children.length > 0">
                      <a-tree-node :key="task.key" :title="task.title">
                        <a-tree-node v-for="(child) in task.children" :key="child.key"
                          :showIcon="child.stepRoleData.wemakerUrl && child.stepRoleData.wemakerUrl.length > 0">
                          <template #title>
                            <a v-if="child.stepRoleData.wemakerUrl
                                  && child.stepRoleData.wemakerUrl.length > 0
                                  && task.closed.includes(child.key)"
                              :href="child.stepRoleData.wemakerUrl"
                              target="_blank"
                              rel="noreferrer noopener">
                              <a-icon slot="prefix" type="link"/>
                              {{child.title}}
                            </a>
                            <strong v-else :style="task.closed.includes(child.key) ? 'color: blue' : 'opacity: 0.5'">{{child.title}}</strong>
                          </template>
                        </a-tree-node>
                      </a-tree-node>
                    </template>
                  </template>
                </a-tree>
                <br>
              </a-card>

              <a-card v-else :bordered="false" class="card-billing-info">
                기획서 승인되면 업무가 정의 됩니다.
              </a-card>

            </a-list-item>
          </a-list>
        </a-card>
      </a-col>
      <a-col v-if="getLayoutValue(stepId).actionVisibility" :span="24" :md="8" class="mb-24">
        <!-- 기획 ----------------------------------------------->
        <ConfirmDesign v-if="loginUser.permission[page.appId].includes(stepId)"/>
        <template v-if="milestoneBoardData.type !== 'stage'">
          <TargetChange v-if="loginUser.permission[page.appId].includes(stepId) && milestoneBoardData.completed_steps.includes(stepId)"/>
        </template>
      </a-col>
    </a-row>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex';
  import ConfirmDesign from "@/components/ActionForm/ConfirmDesign";
  import TargetChange from "@/components/ActionForm/TargetChange";
  import BoardHeader from "@/components/Step/Parts/BoardHeader";

  import getStepMembers from '@/mixins/getStepMembers';

  export default ({
    mixins: [
      getStepMembers,
    ],
    components: {
      BoardHeader,
      ConfirmDesign,
      TargetChange,
    },
    computed: {
      ...mapGetters([
        'page',
        'loginUser',
        'milestoneBoardData',
        'apps',
        'config',
      ]),
    },

    methods: {
      getHistoryVisibility(item) {
        const showConfirmOnly = this.showConfirmOnly;
        const isSameVersion =  this.milestoneBoardData.version === item.milestone_version;
        const isApprove = item.approve;

        return (isApprove && isSameVersion) || showConfirmOnly ? true : false;
      },

      checkDownloadable(stepId, item) {
        let hasAuthority = false;

        this.config.step[stepId].authority.forEach((authority) => {
          if (this.loginUser.permission[this.page.appId].includes(authority) && this.milestoneBoardData.status !== 'close') {
            hasAuthority = true;
          } else if (item.approve && (this.milestoneBoardData.version === item.milestone_version)) {
            hasAuthority = true;
          }
        });
        return hasAuthority;
      },

      toggleShowConfirmOnly() {
        this.showConfirmOnly = !this.showConfirmOnly;
      },

    },
    data() {
      return {
        stepId: 'confirm_game',
        header: '디자인 내부 확인',
        showConfirmOnly: false,
        bodyStyle: { paddingTop: 0, paddingBottom: '16px' },
      };
    },
  })

</script>

<style lang="scss">
  .confirm_game {
    .ant-card-body {
      display: block!important;
    }
  }
</style>

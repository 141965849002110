import connector from '@/store/util/connector';
import storageAvailable from '@/filters/storage';

import {
  INITIALIZE_APP,
  ACTION_PAGE_TRIGGER,
  GET_LOGIN_USER_DATA,

  TEST_STORAGE,
  GET_STORAGE,
  SET_STORAGE,
  GET_NOTICE_DATA,

  GET_MILESTONE_LIST_DATA,
  GET_MILESTONE_OVERVIEW_DATA,
  GET_MILESTONE_OVERVIEW_DETAIL_DATA,
  GET_MILESTONE_LOG_DATA,
  GET_MILESTONE_BOARD_DATA,

  SUBMIT_APP_SETUP,
  SUBMIT_ADD_MILESTONE,
  SUBMIT_DELETE_MILESTONE,
  SUBMIT_CLOSE_MILESTONE,

  SUBMIT_JENKINS_REGISTRATION,

  // submit
  SUBMIT_UPLOAD_PLAN,
  SUBMIT_CONFIRM_PLAN,
  SUBMIT_DEPLOY_DESIGN,
  SUBMIT_DEPLOY_DESIGN_AIIMAGE,
  SUBMIT_DEPLOY_DESIGN_CHARACTERDESIGN,
  SUBMIT_DEPLOY_DESIGN_CONCEPTART,
  SUBMIT_DEPLOY_DESIGN_MOTION,
  SUBMIT_DEPLOY_DESIGN_RETOUCH,
  SUBMIT_DEPLOY_DESIGN_UI,
  SUBMIT_UPLOAD_TESTCASE,
  SUBMIT_BUILD_APPSTORE,
  SUBMIT_BUILD_PLAYSTORE,
  SUBMIT_BUILD_ONESTORE,
  SUBMIT_BUILD_GALAXYSTORE,
  SUBMIT_BUILD_FACEBOOKSTORE,

  SUBMIT_BUILD_APPSTORE_MANUAL,
  SUBMIT_BUILD_PLAYSTORE_MANUAL,
  SUBMIT_BUILD_ONESTORE_MANUAL,
  SUBMIT_BUILD_GALAXYSTORE_MANUAL,
  SUBMIT_BUILD_FACEBOOKSTORE_MANUAL,

  SUBMIT_DEPLOY_SERVER,
  SUBMIT_DEPLOY_STATIC_SHEETS,
  SUBMIT_CONFIRM_STAGE,
  SUBMIT_TARGET_CHANGE,
  SUBMIT_UPLOAD_DAILY_REPORT,
  SUBMIT_UPLOAD_FINAL_REPORT,
  SUBMIT_REVIEW_SERVER,
  // SUBMIT_CONFIRM_LIVE,
  SUBMIT_UPLOAD_BUILD_MARKET,

  SUBMIT_KPI,
  SUBMIT_CHANGE_WORKERS,


  // alarm
  SET_SLACK_ALARM,
} from './actions-types';


export default {
  [INITIALIZE_APP]() {
    // console.log('INITIALIZE_APP (action) =========================== ');
  },

  [TEST_STORAGE]() {
    // console.log('TEST_STORAGE (action) =========================== ');
    if (storageAvailable('sessionStorage') && storageAvailable('localStorage')) {
      this.dispatch('GET_STORAGE');
    } else {
      alert('새로운 알림을 받으시려면 chrome 브라우저로 다시 시도해 주세요');
    }
  },

  [GET_STORAGE]() {
    // console.log('GET_STORAGE (action) =========================== ');
    // console.log(sessionStorage.getItem("ID"));
  },

  [SET_STORAGE]() {
    // console.log('SET_STORAGE (action) =========================== ');
    // sessionStorage.setItem('ID','depgom');
  },

  [GET_NOTICE_DATA]({state}) {
    // console.log('GET_NOTICE_DATA (action) =========================== ');
    const url = '/milestone/notice/v1.json';
    const params = {
      app: Object.keys(state.apps),
      milestone: {},
    };

    if (state.page.milestoneId) {
      params.milestone[state.page.appId] = state.page.milestoneId;
    } else {
      if (state.milestoneListData.length > 0) {
        const milestoneList = [];

        state.milestoneListData.forEach((milestone, index) => {
          if (index < 5) {
            milestoneList.push(milestone.key);
          }
        });
        params.milestone[state.page.appId] = milestoneList;
      }
    }
    connector.getNoticeAPI(this, state, url, params);
  },

  [ACTION_PAGE_TRIGGER]({ state }) {
    // console.log('ACTION_PAGE_TRIGGER (action) =========================== ');
    this.commit('SET_TARGET_PAGE');
    if ({}.hasOwnProperty.call(state.apps, state.page.appId)) {
      if (state.page.milestoneId) {
        this.commit('SET_MILESTONE_BOARD_DATA');
        this.dispatch('GET_MILESTONE_BOARD_DATA');
      } else {
        this.commit('SET_MILESTONE_LIST_DATA', []);
        this.dispatch('GET_MILESTONE_LIST_DATA', null);
        this.dispatch('GET_MILESTONE_OVERVIEW_DATA', 'month');
      }
    } else {
      alert('권한이 없습니다. 담당자(이형주)에게 '+state.page.appId+'에 관하여 문의 주세요.');
    }
  },

  [GET_LOGIN_USER_DATA](state) {
    // console.log('GET_LOGIN_USER_DATA (action) =========================== ');
    const url = '/user/v1.json';
    const params = {};

    connector.getLoginUserAPI(this, state, url, params);
  },

  [GET_MILESTONE_LIST_DATA]({ state }, payload) {
    // console.log('GET_MILESTONE_LIST_DATA (action) =========================== ',payload);
    const url = '/milestone/v1.json';
    const params = {
      app: state.page.appId,
      nextKey: payload,
    };

    connector.getMilestoneListAPI(this, state, url, params);
  },

  [GET_MILESTONE_OVERVIEW_DATA]({ state }, payload) {
    // console.log('GET_MILESTONE_OVERVIEW_DATA (action) =========================== ',payload);
    const url = '/milestone/overview/v1.json';
    const params = {
      app: state.page.appId,
      date_group: payload,
    };

    connector.getMilestoneOverviewAPI(this, state, url, params);
  },

  [GET_MILESTONE_OVERVIEW_DETAIL_DATA]({ state }, payload) {
    // console.log('GET_MILESTONE_OVERVIEW_DETAIL_DATA (action) =========================== ',payload);
    const url = '/milestone/overview/detail/v1.json';
    const params = payload;

    connector.getMilestoneOverviewDetailAPI(this, state, url, params);
  },

  [GET_MILESTONE_LOG_DATA](state, payload) {
    // console.log('GET_MILESTONE_LOG_DATA (action) =========================== ');
    const url = '/milestone/action/logs/v1.json';
    const params = payload;

    connector.getMilestoneLogAPI(this, state, url, params);
  },

  [GET_MILESTONE_BOARD_DATA]({ state }) {
    // console.log('GET_MILESTONE_BOARD_DATA (action) =========================== ');
    const url = '/board/v1.json';
    const params = {
      app: state.page.appId,
      key: state.page.milestoneId,
    };

    connector.getMilestoneBoardAPI(this, state, url, params);
  },

  // //////////////////////////////////////////////////////////////////////////////////////////
  [SUBMIT_APP_SETUP](state, payload) {
    // console.log('SUBMIT_APP_SETUP (action) =========================== ', payload);
    const url = '/milestone/setup/v1.json';
    const params = payload;

    connector.submitAppSetupAPI(this, state, url, params);
  },

  [SUBMIT_ADD_MILESTONE](state, payload) {
    // console.log('SUBMIT_ADD_MILESTONE (action) =========================== ', payload);
    const url = '/milestone/new/v1.json';
    const params = payload;

    connector.submitAddMilestoneAPI(this, state, url, params);
  },

  [SUBMIT_DELETE_MILESTONE](state, payload) {
    // console.log('SUBMIT_DELETE_MILESTONE (action) =========================== ');
    const url = '/board/delete/v1.json';
    const params = payload;

    connector.submitDeleteOrCloseMilestoneAPI(this, state, url, params);
  },

  [SUBMIT_CLOSE_MILESTONE](state, payload) {
    // console.log('SUBMIT_CLOSE_MILESTONE (action) =========================== ');
    const url = '/board/close/v1.json';
    const params = payload;

    connector.submitDeleteOrCloseMilestoneAPI(this, state, url, params);
  },

  // //////////////////////////////////////////////////////////////////////////////////////////
  [SUBMIT_JENKINS_REGISTRATION](state, payload) {
    // console.log('SUBMIT_UPLOAD_PLAN (action) =========================== ', payload);
    const url = '/board/jenkins/v1.json';
    const params = payload;

    connector.submitJenkinsRegistrationAPI(this, state, url, params);
  },

  // //////////////////////////////////////////////////////////////////////////////////////////
  [SUBMIT_UPLOAD_PLAN](state, payload) {
    // console.log('SUBMIT_UPLOAD_PLAN (action) =========================== ', payload);
    const url = '/board/plan/new/v1.json';
    const params = payload;

    connector.submitUploadPlanAPI(this, state, url, params);
  },

  [SUBMIT_CONFIRM_PLAN](state, payload) {
    // console.log('SUBMIT_CONFIRM_PLAN (action) =========================== ', payload);
    const url = '/board/plan/approve/v1.json';
    const params = payload;

    connector.submitConfirmPlanAPI(this, state, url, params);
  },

  [SUBMIT_UPLOAD_TESTCASE](state, payload) {
    // console.log('SUBMIT_UPLOAD_TESTCASE (action) =========================== ', payload);
    const url = '/board/testcase/new/v1.json';
    const params = payload;

    connector.submitUploadTestcaseAPI(this, state, url, params);
  },
  [SUBMIT_DEPLOY_DESIGN](state, payload) {
    // console.log('SUBMIT_DEPLOY_DESIGN (action) =========================== ', payload);
    const url = '/board/design/v1.json';
    const params = payload;

    connector.submitDeployDesignAPI(this, state, url, params);
  },
  [SUBMIT_DEPLOY_DESIGN_AIIMAGE](state, payload) {
    // console.log('SUBMIT_DEPLOY_DESIGN_AIIMAGE (action) =========================== ', payload);
    const url = '/board/design/aiimage/v1.json';
    const params = payload;

    connector.submitDeployDesignAPI(this, state, url, params);
  },
  [SUBMIT_DEPLOY_DESIGN_CHARACTERDESIGN](state, payload) {
    // console.log('SUBMIT_DEPLOY_CHARACTERDESIGN (action) =========================== ', payload);
    const url = '/board/design/characterdesign/v1.json';
    const params = payload;

    connector.submitDeployDesignAPI(this, state, url, params);
  },
  [SUBMIT_DEPLOY_DESIGN_CONCEPTART](state, payload) {
    // console.log('SUBMIT_DEPLOY_CONCEPTART (action) =========================== ', payload);
    const url = '/board/design/conceptart/v1.json';
    const params = payload;

    connector.submitDeployDesignAPI(this, state, url, params);
  },
  [SUBMIT_DEPLOY_DESIGN_MOTION](state, payload) {
    // console.log('SUBMIT_DEPLOY_MOTION (action) =========================== ', payload);
    const url = '/board/design/motion/v1.json';
    const params = payload;

    connector.submitDeployDesignAPI(this, state, url, params);
  },
  [SUBMIT_DEPLOY_DESIGN_RETOUCH](state, payload) {
    // console.log('SUBMIT_DEPLOY_DESIGN_RETOUCH (action) =========================== ', payload);
    const url = '/board/design/retouch/v1.json';
    const params = payload;

    connector.submitDeployDesignAPI(this, state, url, params);
  },
  [SUBMIT_DEPLOY_DESIGN_UI](state, payload) {
    // console.log('SUBMIT_DEPLOY_DESIGN_UI (action) =========================== ', payload);
    const url = '/board/design/ui/v1.json';
    const params = payload;

    connector.submitDeployDesignAPI(this, state, url, params);
  },

  [SUBMIT_BUILD_APPSTORE](state, payload) {
    // console.log('SUBMIT_BUILD_APPSTORE (action) =========================== ', payload);
    const url = '/board/build/appstore/v1.json';
    const params = payload;

    connector.submitBuildAppStoreAPI(this, state, url, params);
  },
  [SUBMIT_BUILD_PLAYSTORE](state, payload) {
    // console.log('SUBMIT_BUILD_PLAYSTORE (action) =========================== ', payload);
    const url = '/board/build/playstore/v1.json';
    const params = payload;

    connector.submitBuildPlayStoreAPI(this, state, url, params);
  },
  [SUBMIT_BUILD_ONESTORE](state, payload) {
    // console.log('SUBMIT_BUILD_ONESTORE (action) =========================== ', payload);
    const url = '/board/build/onestore/v1.json';
    const params = payload;

    connector.submitBuildOneStoreAPI(this, state, url, params);
  },
  [SUBMIT_BUILD_GALAXYSTORE](state, payload) {
    // console.log('SUBMIT_BUILD_GALAXYSTORE (action) =========================== ', payload);
    const url = '/board/build/galaxystore/v1.json';
    const params = payload;

    connector.submitBuildGalaxyStoreAPI(this, state, url, params);
  },
  [SUBMIT_BUILD_FACEBOOKSTORE](state, payload) {
    // console.log('SUBMIT_BUILD_FACEBOOKSTORE (action) =========================== ', payload);
    const url = '/board/build/facebookstore/v1.json';
    const params = payload;

    connector.submitBuildFacebookStoreAPI(this, state, url, params);
  },

  // todo: 임시
  [SUBMIT_BUILD_APPSTORE_MANUAL](state, payload) {
    // console.log('SUBMIT_BUILD_APPSTORE_MANUAL (action) =========================== ', payload);
    const url = '/board/build/appstore/manual/v1.json';
    const params = payload;

    connector.submitBuildAppStoreAPI(this, state, url, params);
  },
  [SUBMIT_BUILD_PLAYSTORE_MANUAL](state, payload) {
    // console.log('SUBMIT_BUILD_PLAYSTORE_MANUAL (action) =========================== ', payload);
    const url = '/board/build/playstore/manual/v1.json';
    const params = payload;

    connector.submitBuildPlayStoreAPI(this, state, url, params);
  },
  [SUBMIT_BUILD_ONESTORE_MANUAL](state, payload) {
    // console.log('SUBMIT_BUILD_ONESTORE_MANUAL (action) =========================== ', payload);
    const url = '/board/build/onestore/manual/v1.json';
    const params = payload;

    connector.submitBuildOneStoreAPI(this, state, url, params);
  },
  [SUBMIT_BUILD_GALAXYSTORE_MANUAL](state, payload) {
    // console.log('SUBMIT_BUILD_GALAXYSTORE_MANUAL (action) =========================== ', payload);
    const url = '/board/build/galaxystore/manual/v1.json';
    const params = payload;

    connector.submitBuildGalaxyStoreAPI(this, state, url, params);
  },
  [SUBMIT_BUILD_FACEBOOKSTORE_MANUAL](state, payload) {
    // console.log('SUBMIT_BUILD_FACEBOOKSTORE_MANUAL (action) =========================== ', payload);
    const url = '/board/build/facebookstore/manual/v1.json';
    const params = payload;

    connector.submitBuildFacebookStoreAPI(this, state, url, params);
  },
  //  todo: 임시


  [SUBMIT_DEPLOY_SERVER](state, payload) {
    // console.log('SUBMIT_DEPLOY_SERVER (action) =========================== ', payload);
    const url = '/board/server/deploy/v1.json';
    const params = payload;

    connector.submitDeployServerAPI(this, state, url, params);
  },
  [SUBMIT_DEPLOY_STATIC_SHEETS](state, payload) {
    // console.log('SUBMIT_DEPLOY_STATIC_SHEETS (action) =========================== ', payload);
    const url = '/board/staticsheets/apply/v1.json';
    const params = payload;

    connector.submitDeployStaticSheetsAPI(this, state, url, params);
  },
  [SUBMIT_CONFIRM_STAGE](state, payload) {
    // console.log('SUBMIT_CONFIRM_STAGE (action) =========================== ', payload);
    const url = '/board/confirm/stage/v1.json';
    const params = payload;

    connector.submitConfirmStageAPI(this, state, url, params);
  },
  [SUBMIT_TARGET_CHANGE](state, payload) {
    // console.log('SUBMIT_CONFIRM_STAGE (action) =========================== ', payload);
    const url = '/board/change/target/v1.json';
    const params = payload;

    connector.submitTargetChangeAPI(this, state, url, params);
  },
  [SUBMIT_UPLOAD_DAILY_REPORT](state, payload) {
    // console.log('SUBMIT_UPLOAD_DAILY_REPORT (action) =========================== ', payload);
    const url = '/board/qa/report/v1.json';
    const params = payload;

    connector.submitUploadDailyReportAPI(this, state, url, params);
  },
  [SUBMIT_UPLOAD_FINAL_REPORT](state, payload) {
    // console.log('SUBMIT_UPLOAD_FINAL_REPORT (action) =========================== ', payload);
    const url = '/board/final/report/v1.json';
    const params = payload;

    connector.submitUploadFinalReportAPI(this, state, url, params);
  },
  [SUBMIT_REVIEW_SERVER](state, payload) {
    // console.log('SUBMIT_REVIEW_SERVER (action) =========================== ', payload);
    const url = '/board/code/review/v1.json';
    const params = payload;

    connector.submitReviewServerAPI(this, state, url, params);
  },
  [SUBMIT_UPLOAD_BUILD_MARKET](state, payload) {
    // console.log('SUBMIT_UPLOAD_BUILD_MARKET (action) =========================== ', payload);
    const url = '/board/update/market/review/v1.json';
    const params = payload;

    connector.submitUploadBuildMarketAPI(this, state, url, params);
  },

  [SUBMIT_KPI](state, payload) {
    // console.log('SUBMIT_KPI (action) =========================== ', payload);
    const url = '/board/stats/push/v1.json';
    const params = payload;

    connector.submitKPiAPI(this, state, url, params);
  },

  [SUBMIT_CHANGE_WORKERS](state, payload) {
    // console.log('SUBMIT_CHANGE_WORKERS (action) =========================== ', payload);
    const url = '/board/set/workers/v1.json';
    const params = payload;

    connector.submitChangeWorkers(this, state, url, params);
  },


  // //////////////////////////////////////////////////////////////////////////////////////////
  [SET_SLACK_ALARM](state, payload) {
    // console.log('SET_SLACK_ALARM (action) =========================== ', payload);
    const url = '/system/alarm/slack/v1.json';
    const params = {
      message: payload,
    };

    connector.setSlackAlarmAPI(this, url, params);
  },
};

<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="bodyStyle">
    <template #title>
      <h6>Result</h6>
      <p><span class="text-success">완료</span>된 마일스톤만 표기 됩니다.</p>
    </template>
    <div style="padding: 10px">
      <a-radio-group  v-model="selected" button-style="solid" @change="onSelectType">
        <a-radio-button value="month" >month</a-radio-button>
        <a-radio-button value="quarter" >quarter</a-radio-button>
      </a-radio-group>
      <br><br>
      <a-table v-if="milestoneOverviewData.length > 0"
               :class="name"
               :columns="milestoneColumns"
               :data-source="milestoneOverviewData"
               :rowClassName="getRowClassName"
               :pagination="false"
               rowKey="title"
               :expanded-row-keys="expandedRowKeys"
               @expandedRowsChange="rowsChange">
        <div slot="expandedRowRender" class="expandedRowRender" slot-scope="record" :key="`_${record.title}_`">

          <a-list item-layout="vertical" :data-source="record.detail">
            <a-list-item slot="renderItem" slot-scope="item">
              {{item.key}} )&nbsp;&nbsp;
              <router-link :to="`${item.app}/${item.key}`" style="font-weight: 600">
                {{item.title}}
              </router-link>( {{item.day}}일 소요 )
              <ul v-if="Object.keys(item.data).length > 0">
                <li v-for="(stepId) in Object.keys(item.data)" :key="stepId">
                  <span>{{config.step[stepId].label}} </span>
                  (스테이지 개발 작업 <span>{{item.data[stepId].days}}일</span>,
                  총 커밋: <span>{{item.data[stepId].commit}}회</span>)
                </li>
              </ul>
              <template v-else>
                <span style="color: #ccc">링크로 이동하셔서 차트에 저장 버튼을 클릭해 주세요</span>
              </template>
            </a-list-item>
          </a-list>
        </div>

        <template slot="vol1" slot-scope="vol1" >
          <span v-if="vol1">
            {{vol1.cnt}} ({{vol1.day}}일)
          </span>
          <span v-else> - </span>
        </template>
        <template slot="vol2" slot-scope="vol2" >
          <span v-if="vol2">
            {{vol2.cnt}} ({{vol2.day}}일)
          </span>
          <span v-else> - </span>
        </template>
        <template slot="vol3" slot-scope="vol3" >
          <span v-if="vol3">
            {{vol3.cnt}} ({{vol3.day}}일)
          </span>
          <span v-else> - </span>
        </template>
        <template slot="vol4" slot-scope="vol4" >
          <span v-if="vol4">
            {{vol4.cnt}} ({{vol4.day}}일)
          </span>
          <span v-else> - </span>
        </template>
        <template slot="vol5" slot-scope="vol5" >
          <span v-if="vol5">
            {{vol5.cnt}} ({{vol5.day}}일)
          </span>
          <span v-else> - </span>
        </template>
      </a-table>

    </div>
  </a-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "MilestoneTable.vue",
  computed: {
    ...mapGetters([
      'loginUser',
      'page',
      'apps',
      'config',
      'notice',
      'milestoneOverviewData',
    ]),
  },
  methods: {
    onSelectType(e) {
      this.$store.dispatch('GET_MILESTONE_OVERVIEW_DATA', e.target.value);
    },
    getRowClassName(record, index) {
      return (index === this.milestoneOverviewData.length - 1) ? 'total' : ''
    },
    rowsChange(rows) {
      const selectItem = rows[rows.length - 1];

      this.expandedRowKeys = [selectItem]
      this.milestoneOverviewData.forEach((milestone) => {
        if (selectItem !== '' && milestone.title === selectItem && milestone.detail.length === 0) {
          this.$store.dispatch('GET_MILESTONE_OVERVIEW_DETAIL_DATA', {
            app: this.page.appId,
            date_group: this.selected,
            title: selectItem,
          });
        }
      });
    },
  },
  data() {
    return {
      name: 'MilestoneOverview',
      selected: 'month',
      bodyStyle: {padding: 0,},
      expandedRowKeys: [],
      milestoneColumns: [
        {
          title: 'TITLE',
          dataIndex: 'name',
          // scopedSlots: { customRender: 'title' },
        },
        {
          title: 'vol1',
          dataIndex: 'vol1',
          scopedSlots: { customRender: 'vol1' },
          // width: 30,
        },
        {
          title: 'vol2',
          dataIndex: 'vol2',
          scopedSlots: { customRender: 'vol2' },
          // width: 30,
        },
        {
          title: 'vol3',
          dataIndex: 'vol3',
          scopedSlots: { customRender: 'vol3' },
          // width: 30,
        },
        {
          title: 'vol4',
          dataIndex: 'vol4',
          scopedSlots: { customRender: 'vol4' },
          // width: 30,
        },
        {
          title: 'vol5',
          dataIndex: 'vol5',
          scopedSlots: { customRender: 'vol5' },
          // width: 30,
        },
        {
          title: 'count',
          dataIndex: 'voltot',
          // width: 100,
        },
        {
          title: 'total vol',
          dataIndex: 'volacc',
          // width: 110,
        },
        {
          title: 'worker',
          dataIndex: 'worker',
          class: 'font-semibold text-muted text-sm',
          // width: 100,
        },
        {
          title: 'member',
          dataIndex: 'member',
        }
      ],
    }
  },
}
</script>

<style lang="scss">
.MilestoneOverview {
  .ant-table-content {
    th ,td {
      padding: 10px 0;
      text-align: center;
      .expandedRowRender {
        text-align: left;
      }
    }
    .total {
      td {
        font-weight: 600;
        padding: 20px 5px;
        background-color: #eee;
        color: black;
      }
    }
  }
}
</style>

<template>
	<div :class="stepId">
		<!-- 기획서 승인 ----------------------------------------------->
		<a-row type="flex" align="middle">
			<a-col :span="24" :md="24">
				<h6 class="font-semibold m-0">기획서 승인</h6>
			</a-col>
		</a-row>
		<br>
		<template v-if="finalPlan">
			<template v-if="finalPlan.isConfirmMaster">
				<a-button type="primary" disabled="disabled" size="small">이미 결재 하셨습니다.</a-button>
			</template>
			<template v-else-if="milestoneBoardData.target === 'live'">
				Milestone이 Live 단계로 접어 들어 더이상 수정 할 수 없습니다.
				<br>
				다음 Milestone에서 작업을 진행해 주세요.
			</template>
			<template v-else>
				<ol style="margin: 0; padding-left: 1rem">
					<li>{{finalPlan.milestone_version}}의 기획서를 다운 받아 내용을 확인합니다.</li>
					<li>내용을 확인 하셨다면 기획서 승인을 클릭 합니다.</li>
				</ol>
				<br>
				<a-button type="primary" :disabled="isLoading" size="small" @click="onConfirmPlan(true)">기획서 승인</a-button>
			</template>
		</template>
		<template v-else>
			승인 할 기획서가 없습니다.
		</template>
	</div>
</template>

<script>

  import { mapGetters } from 'vuex';

  export default ({
    props: [],
    computed: {
      ...mapGetters([
        'page',
        'config',
        'loginUser',
        'milestoneBoardData',
        'apps',
      ]),
    },
    mounted() {
      this.setFinalPlan();
    },
    watch: {
      milestoneBoardData() {
        this.setFinalPlan();
      }
    },
    methods: {
      setFinalPlan() {
        let finalPlan = null;
        const step = this.milestoneBoardData.step[this.stepId];

				if (step.history.length > 0) {
          finalPlan = JSON.parse(JSON.stringify(step.history[0]));
        }
        this.isLoading = false;
        this.finalPlan = finalPlan;
      },
      onConfirmPlan(isConfirm) {
        const formData = new FormData();

        this.isLoading = true;
        formData.append('app', this.page.appId);
        formData.append('key', this.page.milestoneId);
        formData.append('version', this.finalPlan.version);
        formData.append('approve', isConfirm);
        formData.append('step', this.stepId);

        this.$store.dispatch('SUBMIT_CONFIRM_PLAN', formData);
			},

    },
    data() {
      return {
        stepId: 'plan',
        finalPlan: null,

        isVisibleTaskModal: false,
        taskInputString: '',
        isLoading: false,
      }
    },
  })

</script>

<style lang="scss" scoped>
	.plan {
		margin-bottom: 2rem;
	}
</style>
<template>
  <a-card :bordered="false" class="header-solid h-full">
    <h5>Worker TimeLine</h5>
    <span>마일스톤 시작부터 종료까지 개발</span>
    <br><br>
    <v-chart class="chart" :option="option" autoresize :style="`height: ${100 + (option.yAxis.max * 30)}px`"/>
    <a-button @click="onSubmit">저장</a-button>
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex';


import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart, BarChart, LineChart, LinesChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';

use([
  CanvasRenderer,
  LinesChart,
  PieChart,
  BarChart,
  LineChart,
  TitleComponent,
  ToolboxComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  components: {
    VChart,
  },
  computed: {
    ...mapGetters([
      'loginUser',
      'page',
      'apps',
      'config',
      'notice',
      'milestoneBoardData',
    ]),
  },
  mounted() {
    if (this.milestoneBoardData && Object.keys(this.milestoneBoardData).length) {
      this.makeChartData(this.milestoneBoardData);
    }
  },
  watch: {
    milestoneBoardData(value) {
      if (value) {

        this.makeChartData(value);
      }
    },
  },
  methods: {
    makeChartData(milestone_) {
      // console.log('--------------------------------------------- = ', milestone_);
      const milestone = { ...milestone_ }

      const today = new Date();
      const createdString = milestone.created
      const releaseString = milestone.release_dt === '' ? this.formatDate(today) : milestone.release_dt
      const created = new Date(createdString)
      const release = milestone.release_dt === '' ? today : new Date(releaseString)
      const series = []
      const isUpdate = this.currentMilestoneLastIndex <= milestone.lastIndex;
      const startIndex = { 0: true }
      let lineNum = 1
      let previousStepOpen = createdString
      const availableSteps = milestone.available_steps
      const hideStep = [
        // "plan",
        // "upload_testcase",
        "deploy_static_sheets",
        // "design",
        // "build_playstore",
        // "build_playstore",
        // "build_onestore",
        // "build_appstore",
        // "deploy_server",
        "code_review",
        // "confirm_game",
        // "qa",
        "upload_build_market",
        // "final_report",
      ]

      if (isUpdate) {
        const steps = []

        milestone.sequence.forEach((items, index) => {
          let open = milestone.created
          let nextIndex = index + 1

          startIndex[nextIndex] = false
          items.forEach((stepId) => {
            // console.log(stepId, '============================================ ' );
            if (availableSteps.includes(stepId) && !hideStep.includes(stepId)) {
              const currentStep = milestone.step[stepId]
              let tempDays = []

              if (!{}.hasOwnProperty.call(currentStep, 'options')) {
                if (currentStep.history.length > 0) {
                  const history = milestone.step[stepId].history
                  const temp = history.filter((el) => (el.env === 'stage' || el.env === null)).map((el) => el.dt)
                  const approve = history[0].approve

                  milestone.step[stepId].status.commit =  history.length
                  if (nextIndex === milestone.sequence.length) {
                    tempDays = [releaseString]
                  } else  {

                    const openDate = new Date(open)
                    let currentDate = ''

                    startIndex[nextIndex] = approve
                    temp.push(previousStepOpen)
                    tempDays = this.setTempDays(temp)
                    currentDate = history.length === 0 ? tempDays[0] : (tempDays[1] ? tempDays[1] : tempDays[0])

                    open = openDate > new Date(currentDate) ? open : currentDate
                  }



                } else {
                  if (startIndex[index]) {
                    tempDays.push(previousStepOpen)
                    tempDays.push(releaseString)
                  }
                }
              }
              milestone.step[stepId].status.dt = tempDays
              steps.push(stepId)
            }
          })
          previousStepOpen = open
        })

        steps.forEach((stepId) => {
          const lineData = milestone.step[stepId].status.dt

          if (lineData.length > 0) {
            const statusDT = milestone.step[stepId].status.dt
            const commit = milestone.step[stepId].status.commit ? milestone.step[stepId].status.commit : 0
            const startDate = new Date(statusDT[0]);
            const endDate = new Date(statusDT[statusDT.length - 1]);
            const lineLength = this.getDatesStartToLast(startDate, endDate)
            const data = lineLength.map((item) => [item, lineNum, lineLength.length, commit])
            const seriesObj = {
              stepId: stepId,
              name: `${this.config.step[stepId].label}`,
              type: 'line',
              lineStyle: {
                width: 15
              },
              showSymbol: true,
              data: data.length === 0 ? [[createdString, lineNum, 0, commit]] : data
            };
            // console.log('seriesObj = ', seriesObj);
            series.push(seriesObj)
            lineNum++
          }
        })

        this.option.xAxis.data = this.getDatesStartToLast(created, release)
        this.option.yAxis.max = lineNum
        this.option.series = series
      }
    },
    setTempDays(temp) {
      const set = new Set(temp);

      return [...set].sort((a, b) => new Date(a) - new Date(b))
    },
    getDatesStartToLast(startDate, lastDate, lineNum) {
      const result = [];
      let count = 0

      while (startDate <= lastDate) {
        count++
        const starDt = startDate.toISOString().split('T')[0]
        const point = lineNum ? [starDt, lineNum] : starDt

        result.push(point);
        startDate.setDate(startDate.getDate() + 1);
      }
      if (lineNum) {
        result.forEach((point) => {
          point.push(count)
        })
      }
      return result;
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = ( (date.getMonth()+1) < 9 ? "0" + (date.getMonth()+1) : (date.getMonth()+1) );
      const day = ( (date.getDate()) < 9 ? "0" + (date.getDate()) : (date.getDate()) )

      return `${year}-${month}-${day}`
    },
    onSubmit() {
      const formData = new FormData();
      const submitValue = {}

      this.option.series.forEach((step) => {
        const stepData = step.data[0]

        submitValue[step.stepId] = {
          days: stepData[2],
          commit: stepData[3],
        }
      })
      this.isLoading = true;
      formData.append('app', this.milestoneBoardData.app,);
      formData.append('key', this.milestoneBoardData.key,);
      formData.append('data', JSON.stringify(submitValue));
      // console.log('submitValue = ', submitValue, '', this.milestoneBoardData.app, '', this.milestoneBoardData.key);
      this.$store.dispatch('SUBMIT_KPI', formData);
    }
  },
  data() {
    return {
      currentMilestoneLastIndex: 0,
      show: [
        "plan",
        "upload_testcase",
        "design",
        "build_playstore",
        "upload_build_market",
        "build_onestore",
        "build_appstore",
        "build_galaxystore",
        "build_facebookstore",
        "deploy_server",
        "deploy_static_sheets"
      ],
      option: {
        tooltip: {

          trigger: 'axis',
          showContent: true,
          formatter(params) {
            let title = '';
            let context = '';

            params.sort((a, b) => {
              if (a.value[1] === b.value[1]) { return 0; } return a.value[1] < b.value[1] ? 1 : -1;
            });
            title = `<div class="tooltipList title">${params[0].axisValue}</div>`;
            params.forEach((line) => {
              context += '<div class="tooltipList context">';
              context += `<span class="label">${line.marker} ${line.seriesName}: </span> `;
              context += `stage 작업일: <span class="value">${line.value[2]}</span>, `;
              context += `총 커밋: <span class="value">${line.value[3]}</span>`;
              context += '</div>';
            });
            return `${title} ${context}`;
          },
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          show: false,
        },
        legend: {},
        xAxis: {
          type: 'category',
          splitLine: {
            show: true
          },
          axisLabel: {
            margin: 30,
            fontSize: 16
          },
          splitArea: {
            show: true
          },
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'time',
          axisLabel: {
            fontSize: 0,
          },
          max: 4,
        },
        series: [],
      },
    };
  },
}
</script>

<style scoped>

</style>

<template>
	<div :class="stepId">
		<!-- UploadTestcase ----------------------------------------------->
		<!--  -->
		<a-row type="flex" align="middle">
			<a-col :span="24" :md="24">
				<h6 class="font-semibold m-0">최종 보고서 입력</h6>
			</a-col>
		</a-row>
		<br>
    <div v-if="(milestoneBoardData.target === 'live' || milestoneBoardData.type === 'stage') && milestoneBoardData.available_steps.includes(stepId)">
			<div>
				<strong>최종 보고서 링크 입력</strong>&nbsp;
				<a-tooltip title="https://docs.google.com/ 링크를 입력해 주세요."><a-icon type="question-circle-o" /></a-tooltip>
				<a-input v-model="final_report_link" placeholder="구글 링크를 입력해 주세요">
					<a-icon slot="prefix" type="link" />
				</a-input>
			</div>
			<br>
      <div>
        <strong>마일스톤 작업 크기</strong>
        <a-select v-model="final_volume" style="width: 100%" >
          <a-select-option v-for="(volume, index) in final_volume_option" :key="index" :value="volume">
            {{volume}}
          </a-select-option>
        </a-select>
      </div>
      <br>
      <div>
        <strong>출시일 입력</strong>&nbsp;
        <a-tooltip title="출시일을 입력해 주세요."><a-icon type="question-circle-o" /></a-tooltip>
        <a-date-picker :format="dateFormat" style="width: 100%;" placeholder="출시일을 입력해 주세요." @change="onChangeDate" />
      </div>
      <br>
			<a-button type="primary" :disabled="isLoading" size="small" @click="onSubmit(true)">기획서 {{milestoneBoardData.version}}에 대응되는 최종보고서</a-button>
		</div>
		<div v-else>
			모든것이 완료 되면 최종 보고서를 입력해 주세요
		</div>
	</div>
</template>

<script>

  import { mapGetters } from 'vuex';
  import validation from '@/filters/validation';

  export default ({
    props: [],
    filter: {
      validation,
    },
    computed: {
      ...mapGetters([
        'page',
        'config',
        'loginUser',
        'milestoneBoardData',
        'apps',
      ]),
    },
    mounted() {
      this.final_report_link = this.milestoneBoardData.final_report
      this.setCurrentAction();
    },
    watch: {
      milestoneBoardData() {
        this.setCurrentAction();
      }
    },
    methods: {
      setCurrentAction() {
        this.isLoading = false;
      },
      onChangeDate(e, date) {
        this.releaseDate = date
      },
      openNotification(description) {
        this.$notification.open({
          message: '확인해 주세요.',
          description,
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      },
      onSubmit() {
        const formData = new FormData();

        this.isLoading = true;
        if (!this.releaseDate || this.releaseDate === '') {
          this.openNotification('출시일을 입력해 주세요.');
          this.isLoading = false;
        } else if (this.final_volume === 0) {
          this.openNotification('마일스톤의 크기를 0이상 입력해 주세요.');
          this.isLoading = false;
        } else if (!validation.validDocsURL(this.final_report_link)) {
          this.openNotification('링크가 정확한지 다시 한번 확인해 주세요.');
          this.isLoading = false;
        } else {
          formData.append('app', this.page.appId);
          formData.append('key', this.page.milestoneId);
          formData.append('final_report', this.final_report_link);
          formData.append('release_dt', this.releaseDate);
          formData.append('volume', this.final_volume);
          formData.append('members', JSON.stringify(this.milestoneBoardData.members));
          this.$store.dispatch('SUBMIT_UPLOAD_FINAL_REPORT', formData);
        }
			},
    },
    data() {
      return {
        stepId: 'final_report',
        final_report_link: '',
        dateFormat: 'YYYY-MM-DD',
        releaseDate: null,
        final_volume: 0,
        final_volume_option: [1, 2, 3, 4, 5],
        isLoading: false,
      }
    },
  })

</script>

<style lang="scss" scoped>
	.final_report {
		margin-bottom: 2rem;
	}
</style>

export const INITIALIZE_APP = 'INITIALIZE_APP';
export const ACTION_PAGE_TRIGGER = 'ACTION_PAGE_TRIGGER';
export const GET_LOGIN_USER_DATA = 'GET_LOGIN_USER_DATA';

export const TEST_STORAGE = 'TEST_STORAGE';
export const GET_STORAGE = 'GET_STORAGE';
export const SET_STORAGE = 'SET_STORAGE';
export const GET_NOTICE_DATA = 'GET_NOTICE_DATA';

export const SUBMIT_APP_SETUP = 'SUBMIT_APP_SETUP';
export const GET_MILESTONE_LIST_DATA = 'GET_MILESTONE_LIST_DATA';
export const GET_MILESTONE_OVERVIEW_DATA = 'GET_MILESTONE_OVERVIEW_DATA'
export const GET_MILESTONE_OVERVIEW_DETAIL_DATA = 'GET_MILESTONE_OVERVIEW_DETAIL_DATA'
export const GET_MILESTONE_LOG_DATA = 'GET_MILESTONE_LOG_DATA';
export const GET_MILESTONE_BOARD_DATA = 'GET_MILESTONE_BOARD_DATA';

export const SUBMIT_ADD_MILESTONE = 'SUBMIT_ADD_MILESTONE';
export const SUBMIT_DELETE_MILESTONE = 'SUBMIT_DELETE_MILESTONE';
export const SUBMIT_CLOSE_MILESTONE = 'SUBMIT_CLOSE_MILESTONE';

export const SUBMIT_JENKINS_REGISTRATION = 'SUBMIT_JENKINS_REGISTRATION';

export const SUBMIT_UPLOAD_PLAN = 'SUBMIT_UPLOAD_PLAN';
export const SUBMIT_CONFIRM_PLAN = 'SUBMIT_CONFIRM_PLAN';
export const SUBMIT_UPLOAD_TESTCASE = 'SUBMIT_UPLOAD_TESTCASE';
export const SUBMIT_DEPLOY_DESIGN = 'SUBMIT_DEPLOY_DESIGN';
export const SUBMIT_DEPLOY_DESIGN_AIIMAGE = 'SUBMIT_DEPLOY_DESIGN_AIIMAGE';
export const SUBMIT_DEPLOY_DESIGN_CHARACTERDESIGN = 'SUBMIT_DEPLOY_DESIGN_CHARACTERDESIGN';
export const SUBMIT_DEPLOY_DESIGN_CONCEPTART = 'SUBMIT_DEPLOY_DESIGN_CONCEPTART';
export const SUBMIT_DEPLOY_DESIGN_MOTION = 'SUBMIT_DEPLOY_DESIGN_MOTION';
export const SUBMIT_DEPLOY_DESIGN_RETOUCH = 'SUBMIT_DEPLOY_DESIGN_RETOUCH';
export const SUBMIT_DEPLOY_DESIGN_UI = 'SUBMIT_DEPLOY_DESIGN_UI';
export const SUBMIT_BUILD_APPSTORE = 'SUBMIT_BUILD_APPSTORE';
export const SUBMIT_BUILD_PLAYSTORE = 'SUBMIT_BUILD_PLAYSTORE';
export const SUBMIT_BUILD_ONESTORE = 'SUBMIT_BUILD_ONESTORE';
export const SUBMIT_BUILD_GALAXYSTORE = 'SUBMIT_BUILD_GALAXYSTORE';
export const SUBMIT_BUILD_FACEBOOKSTORE = 'SUBMIT_BUILD_FACEBOOKSTORE';

export const SUBMIT_BUILD_APPSTORE_MANUAL = 'SUBMIT_BUILD_APPSTORE_MANUAL';
export const SUBMIT_BUILD_PLAYSTORE_MANUAL = 'SUBMIT_BUILD_PLAYSTORE_MANUAL';
export const SUBMIT_BUILD_ONESTORE_MANUAL = 'SUBMIT_BUILD_ONESTORE_MANUAL';
export const SUBMIT_BUILD_GALAXYSTORE_MANUAL = 'SUBMIT_BUILD_GALAXYSTORE_MANUAL';
export const SUBMIT_BUILD_FACEBOOKSTORE_MANUAL = 'SUBMIT_BUILD_FACEBOOKSTORE_MANUAL';


export const SUBMIT_DEPLOY_SERVER = 'SUBMIT_DEPLOY_SERVER';
export const SUBMIT_DEPLOY_STATIC_SHEETS = 'SUBMIT_DEPLOY_STATIC_SHEETS';
export const SUBMIT_CONFIRM_STAGE = 'SUBMIT_CONFIRM_STAGE';
export const SUBMIT_TARGET_CHANGE = 'SUBMIT_TARGET_CHANGE';
export const SUBMIT_UPLOAD_DAILY_REPORT = 'SUBMIT_UPLOAD_DAILY_REPORT';
export const SUBMIT_UPLOAD_FINAL_REPORT = 'SUBMIT_UPLOAD_FINAL_REPORT';
export const SUBMIT_REVIEW_SERVER = 'SUBMIT_REVIEW_SERVER';
export const SUBMIT_UPLOAD_BUILD_MARKET = 'SUBMIT_UPLOAD_BUILD_MARKET';

// system submit
export const SET_SLACK_ALARM = 'SET_SLACK_ALARM';
export const SUBMIT_KPI = 'SUBMIT_KPI';
export const SUBMIT_CHANGE_WORKERS = 'SUBMIT_CHANGE_WORKERS';

import axios from 'axios/index';
import router from '@/router'

// mockup
// import ReturnFakeLoginData from '../mockup/ReturnFakeLoginData';
// import ReturnFakeMilestoneListData from '../mockup/ReturnFakeMilestoneListData';
// import ReturnFakeMilestoneBoardData from '../mockup/ReturnFakeMilestoneBoardData';

// const messageTypes = ['success', 'warning', 'info', 'error'];
// const messageMethod = ['toast', 'alert', 'confirm', 'notification'];

const getLoginUserAPI = (store, state, url, params) => {
  axios
    .get(url, {
      params,
    })
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload) {
        store.commit('INITIALIZE_USER', res);
      } else {
        alert('잘못됨');
      }
    })
    .catch((err) => {
      alert(err);
    });
};

const getNoticeAPI = (store, state, url, params) => {
  // console.log('getNoticeAPI (connector) =========================== ', url, params);
  axios
    .get(url, {
      params,
    })
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload) {
        store.commit('SET_NOTICE_DATA', payload);
      } else {
        alert('잘못됨');
      }
    })
    .catch((err) => {
      alert(`${err}.\n페이지를 다시 로드합니다.`);
      location.reload();
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const getMilestoneListAPI = (store, state, url, params) => {
  // console.log('getMilestoneListAPI (connector) =========================== ', url, params);
  axios
    .get(url, {
      params,
    })
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload) {
        store.commit('SET_MILESTONE_LIST_DATA', payload.milestoneListData);
      } else {
        alert('잘못됨');
      }
    })
    .catch((err) => {
      alert(err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const getMilestoneOverviewAPI= (store, state, url, params) => {
  // console.log('getMilestoneOverviewAPI (connector) =========================== ', url, params);
  axios
    .get(url, {
      params,
    })
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload) {
        store.commit('SET_MILESTONE_OVERVIEW_DATA', payload.overview);
      } else {
        alert('잘못됨');
      }
    })
    .catch((err) => {
      alert(err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const getMilestoneOverviewDetailAPI= (store, state, url, params) => {
  // console.log('getMilestoneOverviewDetailAPI (connector) =========================== ', url, params);
  axios
    .get(url, {
      params,
    })
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload) {
        store.commit('SET_MILESTONE_OVERVIEW_DETAIL_DATA', {...params, detail: payload});
      } else {
        alert('잘못됨');
      }
    })
    .catch((err) => {
      alert(err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const getMilestoneLogAPI = (store, state, url, params) => {
  // console.log('getMilestoneLogAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload) {
        store.commit('SET_MILESTONE_LOG_DATA', {
          key: JSON.parse(params.get('key')),
          timeLine: payload.logs,
        });
      } else {
        alert('잘못됨');
      }
    })
    .catch((err) => {
      alert(err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const getMilestoneBoardAPI = (store, state, url, params) => {
  // console.log('getMilestoneBoardAPI (connector) =========================== ', url, params);
  store.commit('SET_LOADING', true);
  axios
    .get(url, {
      params,
    })
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert('잘못됨');
      }
    })
    .catch((err) => {
      alert(err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

// //////////////////////////////////////////////////////

const submitAppSetupAPI = (store, state, url, params) => {
  // console.log('submitAddMilestoneAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload['liveOps']) {
        store.commit('SET_APP_LIVE_OPS_DATA', payload);
      } else {
        alert('submitAppSetupAPI' + payload.message);
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitAddMilestoneAPI = (store, state, url, params) => {
  // console.log('submitAddMilestoneAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_LIST_DATA', payload.milestoneListData);
      } else {
        alert(payload.message);
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitDeleteOrCloseMilestoneAPI = (store, state, url, params) => {
  // console.log('submitDeleteOrCloseMilestoneAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        router.push({ name: 'Milestone', params: { id: params.get('app') }});
      } else {
        alert(payload.message);
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

// //////////////////////////////////////////////////////

const submitJenkinsRegistrationAPI = (store, state, url, params) => {
  // console.log('submitJenkinsRegistrationAPI (connector) =========================== ', url, params);
  axios
    .post(url, params, {headers: {'Content-Type': 'multipart/form-data'}})
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        console.log('JenkinsRegistration = 성공');
      } else {
        alert(payload.message);
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

// //////////////////////////////////////////////////////
const submitUploadPlanAPI = (store, state, url, params) => {
  // console.log('submitUploadPlanAPI (connector) =========================== ', url, params);
  axios
    .post(url, params, {headers: {'Content-Type': 'multipart/form-data'}})
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert('기획서가 업로드 되지 못하였습니다.');
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitConfirmPlanAPI = (store, state, url, params) => {
  // console.log('submitConfirmPlanAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert('기획서 승인에 실패 하였습니다.');
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitUploadTestcaseAPI = (store, state, url, params) => {
  // console.log('submitUploadTestcaseAPI (connector) =========================== ', url, params);
  axios
    .post(url, params, {headers: {'Content-Type': 'multipart/form-data'}})
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert('업로드에 실패 하였습니다.');
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitDeployDesignAPI = (store, state, url, params) => {
  // console.log('submitDeployDesignAPI (connector) =========================== ', url, params);
  axios
    .post(url, params, {headers: {'Content-Type': 'multipart/form-data'}})
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert('업로드에 실패 하였습니다.');
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitBuildAppStoreAPI = (store, state, url, params) => {
  // console.log('submitBuildAppStoreAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert(payload.message);
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitBuildPlayStoreAPI = (store, state, url, params) => {
  // console.log('submitBuildPlayStoreAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert(payload.message);
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitBuildOneStoreAPI = (store, state, url, params) => {
  // console.log('submitBuildOneStoreAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert(payload.message);
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitBuildGalaxyStoreAPI = (store, state, url, params) => {
  // console.log('submitBuildGalaxyStoreAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert(payload.message);
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitBuildFacebookStoreAPI = (store, state, url, params) => {
  // console.log('submitBuildFacebookStoreAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert(payload.message);
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitDeployServerAPI = (store, state, url, params) => {
  // console.log('submitDeployServerAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert('배포에 실패 하였습니다.');
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitDeployStaticSheetsAPI = (store, state, url, params) => {
  // console.log('submitDeployStaticSheetsAPI (connector) =========================== ', url, params);
  axios
    .post(url, params, {headers: {'Content-Type': 'multipart/form-data'}})
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert('배포된 내용을 저장하지 못하였습니다.');
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitConfirmStageAPI = (store, state, url, params) => {
  // console.log('submitConfirmStageAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert('확인한 내용을 저장하지 못하였습니다.');
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitTargetChangeAPI = (store, state, url, params) => {
  // console.log('submitTargetChangeAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert('ENV설정을 변경하지 못하였습니다.');
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitUploadDailyReportAPI = (store, state, url, params) => {
  // console.log('submitUploadDailyReportAPI (connector) =========================== ', url, params);
  axios
    .post(url, params, {headers: {'Content-Type': 'multipart/form-data'}})
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert('리포트 업로드에 실패 하였습니다.');
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitUploadFinalReportAPI = (store, state, url, params) => {
  // console.log('submitUploadFinalReportAPI (connector) =========================== ', url, params);
  axios
    .post(url, params, {headers: {'Content-Type': 'multipart/form-data'}})
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert('리포트 업로드에 실패 하였습니다.');
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitReviewServerAPI = (store, state, url, params) => {
  // console.log('submitReviewServerAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert('리뷰한 내용을 저장 하지 못하였습니다.');
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitUploadBuildMarketAPI = (store, state, url, params) => {
  // console.log('submitUploadBuildMarketAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert('마켓에 올린 내용을 저장 하지 못하였습니다.');
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitKPiAPI = (store, state, url, params) => {
  // console.log('submitKPiAPI (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        console.log('성공  = ');
        alert(`내용을 저장 하였습니다. `);
      } else {
        alert('KPi를 저장 하지 못하였습니다.');
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};

const submitChangeWorkers = (store, state, url, params) => {
  // console.log('submiWorkers (connector) =========================== ', url, params);
  axios
    .post(url, params)
    .then((res) => {
      const payload = res.data.data;

      if ({}.hasOwnProperty.call(payload, 'error')) {
        alert(`설정값 오류 : ${url}`);
      } else if (payload.success) {
        store.commit('SET_MILESTONE_BOARD_DATA', payload.milestoneBoardData);
      } else {
        alert('작업자 정보를 저장 하지 못하였습니다.');
      }
    })
    .catch((err) => {
      console.log('틀림 = ', err);
    })
    .finally(() => {
      store.commit('SET_LOADING', false);
    });
};


// //////////////////////////////////////////////////////////////////////////////////////////
const setSlackAlarmAPI = (store, url, params) => {
  axios
    .get(url, {
      params,
    })
    .then((res) => {
      console.log('SET_SLACK_ALARM = ', res);
    })
    .catch((err) => {
      console.log(`==== SET_SLACK_ALARM, ${err} ===`);
    });
};

export default {
  getLoginUserAPI,
  getNoticeAPI,
  getMilestoneListAPI,
  getMilestoneOverviewAPI,
  getMilestoneOverviewDetailAPI,
  getMilestoneLogAPI,
  getMilestoneBoardAPI,

  submitAppSetupAPI,
  submitAddMilestoneAPI,
  submitDeleteOrCloseMilestoneAPI,

  submitJenkinsRegistrationAPI,

  submitUploadPlanAPI,
  submitConfirmPlanAPI,
  submitUploadTestcaseAPI,
  submitDeployDesignAPI,
  submitBuildAppStoreAPI,
  submitBuildPlayStoreAPI,
  submitBuildOneStoreAPI,
  submitBuildGalaxyStoreAPI,
  submitBuildFacebookStoreAPI,
  submitDeployServerAPI,
  submitDeployStaticSheetsAPI,
  submitConfirmStageAPI,
  submitTargetChangeAPI,
  submitUploadDailyReportAPI,
  submitUploadFinalReportAPI,
  submitReviewServerAPI,
  submitUploadBuildMarketAPI,
  submitKPiAPI,
  submitChangeWorkers,

  setSlackAlarmAPI,
};


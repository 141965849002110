<template>
	<div :class="stepId">
    <h6 class="font-semibold m-0">모션 관련 직군</h6>
		<br>
		<div v-if="milestoneBoardData.available_steps.includes(stepId)">
			<div>
				<template>
					<strong>완료한 작업 선택</strong>&nbsp;
					<a-tooltip title="완료한 작업을 중분류 단위로 체크해 주세요."><a-icon type="question-circle-o" /></a-tooltip>
					<br>
					<a-tree v-model="selectedTasks" checkable defaultExpandAll>
            <a-tree-node
              v-for="(task) in milestoneBoardData.tasks"
              :hidden="stepRoleMap[task.key].length === 0"
              :key="task.key" 
              :title="task.title">
              <a-tree-node
                v-for="(child) in task.children"
                :key="child.key"
                :disableCheckbox="!(stepRoleMap[task.key] && stepRoleMap[task.key].includes(child.key))"
                :hidden="!(stepRoleMap[task.key] && stepRoleMap[task.key].includes(child.key))">
                <template #title>
                  <div>{{child.title}}</div>
                </template>
              </a-tree-node>
            </a-tree-node>
					</a-tree>
				</template>
			</div>
      <br>
			<div>
        <strong>디자인 노트 :</strong>&nbsp;
        <a-tooltip title="디자인한 부분의 전체적인 사항을 팀원에게 설명해 주세요."><a-icon type="question-circle-o" /></a-tooltip>
        <a-textarea v-model="description"
                    placeholder="디자인한 내용을 간략하게 기술해 주세요."
                    :auto-size="{ minRows: 2, maxRows: 10 }"/>
        <br>
        <br>
        <strong>작업물 URL :</strong>&nbsp;
        <a-tooltip title="관련된 http://nas 혹은 https://nas 로 시작하는 URL을 기술해 주세요."><a-icon type="question-circle-o" /></a-tooltip>
        <div>
          <a-space direction="horizontal">
            <a-input
              placeholder="작업물의 링크를 입력해 주세요."
              v-model="taskUrl">
              <a-icon slot="prefix" type="link"/>
            </a-input>
          </a-space>
        </div>
        <br>
        <br>
        <a-button type="primary" :disabled="isLoading" size="small" @click="onSubmit">📦 제출하기</a-button>
			</div>
		</div>
		<div v-else>
			기획서가 승인되면 승인된 기획서를 기반으로 디자인해 주세요.
			<br>
			승인된 기획서가 없어 지금은 작업 하실 수 없습니다.
		</div>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import { SUBMIT_DEPLOY_DESIGN_MOTION } from '@/store/actions-types.js';

export default ({
  props: [],
  computed: {
    ...mapGetters([
      'page',
      'config',
      'loginUser',
      'milestoneBoardData',
      'apps',
    ]),
    //Key : TaskKey
    //Value : Children key that current step is in steprole
    stepRoleMap() {
      const result = {};

      this.milestoneBoardData.tasks.forEach((task) => {
        result[task.key] = task.children
          .filter(child => this.isInStepRole(1, child.stepRoles))
          .map(child => child.key);
      });

      return result;
    },
  },
  mounted() {
    this.setCurrentAction();
  },
  watch: {
    milestoneBoardData() {
      this.setCurrentAction();
    }
  },
  methods: {
    setCurrentAction() {
      let newTaskMapKeys = new Set();

      this.milestoneBoardData.tasks.forEach((task) => {
        task.children.forEach((taskChild) => {
          newTaskMapKeys.add(taskChild.key);
        })
      })

      this.taskMapKeys = Array.from(newTaskMapKeys);

      let selectedTasks = [];
      let taskUrl = '';
      const history = this.milestoneBoardData.step[this.stepId].history;

      if (history.length > 0) {
        const recentHistory = history[0];

        selectedTasks = recentHistory.selectedTasks.filter((selectedTask) => {
          return this.taskMapKeys.includes(selectedTask);
        });
        taskUrl = recentHistory.taskUrl;
      }
      this.isLoading = false;

      const taskOutOfStepRoles = this.milestoneBoardData.tasks.reduce((result, task) => {
        let stepRoles = task.children.filter(child => !this.isInStepRole(1, child.stepRoles));
        return result.concat(stepRoles);
      }, [])
        .map((stepRoles) => stepRoles.key);

      this.selectedTasks = [...new Set([...selectedTasks, ...taskOutOfStepRoles])];
      this.taskUrl = taskUrl;
    },
    openNotification(description) {
      this.$notification.open({
        message: '확인해 주세요.',
        description,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    },
    isInStepRole(sequenceLevel, stepRoles) {
      const isInSequence = this.milestoneBoardData.sequence[sequenceLevel].includes(this.stepId);

      return isInSequence && stepRoles.includes(this.stepId);
    },
    onSubmit() {
      const milestoneTasks = this.milestoneBoardData.tasks;
      const allTaskCount = Object.values(milestoneTasks)
        .reduce((acc, curr) => acc + curr.children.length, 0);
      const haveToDoneCount = Object.values(this.stepRoleMap)
        .reduce((acc, curr) => acc + curr.length, 0);
      const doneCount = this.selectedTasks.filter(element => typeof element === 'string').length;
      const formData = new FormData();
      let description = this.description;
      let taskUrl = this.taskUrl;

      this.isLoading = true;
      if (description === '') {
        this.openNotification('빠진 부분이 있는지 다시 한번 확인해 주세요.');
        this.isLoading = false;
        return;
      }
      if ((allTaskCount - doneCount >= haveToDoneCount )) {
        this.openNotification('완료된 디자인을 하나 이상 선택해 주세요.');
        this.isLoading = false;
        return;
      }

      let lowerTaskUrl = taskUrl.toLowerCase();

      if ((lowerTaskUrl.startsWith('https://nas') === false)
        && (lowerTaskUrl.startsWith('http://nas') === false)) {
        this.openNotification('디자인의 URL중 http(s)://nas로 시작하지 않는 것이 있습니다.');
        this.isLoading = false;
        return;
      }

      formData.append('app', this.page.appId);
      formData.append('key', this.page.milestoneId);
      formData.append('milestone_version', this.milestoneBoardData.version);
      formData.append('description', description);
      formData.append('env', this.milestoneBoardData.target);
      formData.append('selectedTasks', JSON.stringify(this.selectedTasks));
      formData.append('taskUrl', taskUrl);
      formData.append('step', this.stepId);

      this.$store.dispatch(SUBMIT_DEPLOY_DESIGN_MOTION, formData);
    },

  },
  data() {
    return {
      stepId: 'motion',
      description: '',
      selectedTasks: [],
      taskUrl: '',
      taskMapKeys : [],
      isLoading: false,
    }
  },
})

</script>

<style lang="scss" scoped>
	.deploy_server {
		margin-bottom: 2rem;
	}
</style>

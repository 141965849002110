<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }">

    <template v-if="milestoneBoardData && {}.hasOwnProperty.call(milestoneBoardData, 'step')" #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h4 class="font-semibold m-0">
            {{{}.hasOwnProperty.call(apps, page.appId) ? apps[page.appId].full_name : page.appId}} {{milestoneBoardData.version}} {{milestoneBoardData.title}}
            <span v-if="milestoneBoardData.type === 'hotfix'"  style="color: #999; font-size: 1rem; font-weight: 100; color: blue"> - Hotfix </span>
          </h4>
          <br><br>
          <h5 class="font-semibold m-0"></h5>
        </a-col>
      </a-row>
    </template>

    <BoardChart/>
    <br><br>
    <div v-if="milestoneBoardData && {}.hasOwnProperty.call(milestoneBoardData, 'step')">
      <Plan v-if="milestoneBoardData.steps.includes('plan')"/>
      <DesignConceptArt v-if="milestoneBoardData.steps.includes('concept_art')"/>
      <DesignCharacterDesign v-if="milestoneBoardData.steps.includes('character_design')"/>
      <DesignMotion v-if="milestoneBoardData.steps.includes('motion')"/>
      <DesignUI v-if="milestoneBoardData.steps.includes('ui')"/>
      <DesignAIImage v-if="milestoneBoardData.steps.includes('ai_image')"/>
      <DesignRetouch v-if="milestoneBoardData.steps.includes('retouch')"/>
      <ConfirmDesign v-if="milestoneBoardData.steps.includes('confirm_game')"/>
      <FinalReport v-if="milestoneBoardData.steps.includes('final_report')"/>
    </div>

    <br>
    <div style="text-align: right">
      <template v-if="milestoneBoardData && {}.hasOwnProperty.call(milestoneBoardData, 'final_report') && milestoneBoardData.final_report === ''">
        <template v-if="loginUser.permission[page.appId].includes('confirm_game') && milestoneBoardData.status === 'create'">
          <a-button v-if="milestoneBoardData.status !== 'create'" size="small" @click="showCloseMilestoneModal">마일스톤 삭제</a-button>
          <a-button v-else size="small" @click="showCloseMilestoneModal">마일스톤 종료</a-button>
          <a-modal title="확인해 주세요."
                   v-model="isVisibleCloseMilestoneModal"
                   :confirm-loading="confirmLoading"
                   @ok="submitCloseMilestone">
            <p>
              이 마일스톤의 상태를 Close로 변경합니다.<br/>
              Close된 마일스톤은 더 이상 수정할 수 없습니다.<br/>
              진행된 마일스톤이 아니라면, 삭제됩니다.
            </p>
          </a-modal>
        </template>
      </template>
    </div>

  </a-card>
</template>

<script>

import { mapGetters } from 'vuex';

import Plan from "@/components/Step/Plan";
import ConfirmDesign from "@/components/Step/ConfirmDesign";
import FinalReport from "@/components/Step/FinalReport";
import BoardChart from '@/components/Milestone/BoardChart'

import validation from '@/filters/validation';
import DesignAIImage from '../Step/DesignAIImage.vue';
import DesignCharacterDesign from '../Step/DesignCharacterDesign.vue';
import DesignConceptArt from '../Step/DesignConceptArt.vue';
import DesignMotion from '../Step/DesignMotion.vue';
import DesignRetouch from '../Step/DesignRetouch.vue';
import DesignUI from '../Step/DesignUI.vue';


export default ({
  props: [],
  filter: {
    validation,
  },
  components: {
  Plan,
  DesignAIImage,
  DesignCharacterDesign,
  DesignConceptArt,
  DesignMotion,
  DesignRetouch,
  DesignUI,
  ConfirmDesign,
  FinalReport,
  BoardChart
},
  computed: {
    ...mapGetters([
      'apps',
      'page',
      'config',
      'loginUser',
      'milestoneBoardData',
    ]),
  },
  methods: {
    showCloseMilestoneModal() {
      this.isVisibleCloseMilestoneModal = true;
    },
    openNotification(description) {
      this.$notification.open({
        message: '확인해 주세요.',
        description,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    },
    submitCloseMilestone() {
      const formData = new FormData();

      formData.append('app', this.page.appId);
      formData.append('key', this.page.milestoneId);
      if (this.milestoneBoardData.completed_steps.length === 0) {
        this.$store.dispatch('SUBMIT_DELETE_MILESTONE', formData);
      } else {
        this.$store.dispatch('SUBMIT_CLOSE_MILESTONE', formData);
      }
      this.isVisibleCloseMilestoneModal = false;
    },
  },
  data() {
    return {
      isVisibleCloseMilestoneModal: false,
      finalReport: '',

      ModalText: 'Content of the modal',
      confirmLoading: false,
    }
  },
})

</script>
